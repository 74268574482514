import React, { useState } from 'react';

const TasksList = ({
  selectedProjectId,
  sortedTasks,
  openModal,
  users,
  getUserNameById,
  sortTasks,
  sortConfig,
  searchTerm,
  selectedStatuses,
  selectedAssigners,
  selectedExecutors,
  selectedTags, 
  searchTermModal
}) => {
  const [expandedTasks, setExpandedTasks] = useState({});

  const toggleSubtasks = (taskId) => {
    setExpandedTasks(prev => ({
      ...prev,
      [taskId]: !prev[taskId]
    }));
  };

  const buildTaskHierarchy = (tasks) => {
    const taskMap = {};
    const rootTasks = [];

    tasks.forEach(task => {
      taskMap[task.id] = { ...task, subtasks: [] };
    });
    tasks.forEach(task => {
      if (task.parent_task_id !== null) {
        if (taskMap[task.parent_task_id]) {
          taskMap[task.parent_task_id].subtasks.push(taskMap[task.id]);
        } else {
          rootTasks.push(taskMap[task.id]);
        }
      } else {
        rootTasks.push(taskMap[task.id]);
      }
    });
    return rootTasks;
  };

  const tasksWithSubtasks = buildTaskHierarchy(sortedTasks);

  const filterTasksRecursively = (task) => {
    const assignerName = getUserNameById(task.assigner_id, users);
    const executorName = getUserNameById(task.executor_id, users);
    const searchLower = searchTerm ? searchTerm.toLowerCase() : '';

    const matchesSearch =
      task.title.toLowerCase().includes(searchLower) ||
      (assignerName && assignerName.toLowerCase().includes(searchLower)) ||
      (executorName && executorName.toLowerCase().includes(searchLower));

    const matchesTitle = task.title.toLowerCase().includes(searchTermModal.toLowerCase());
    const matchesAssigner = selectedAssigners.length === 0 || selectedAssigners.includes(task.assigner_id);
    const matchesExecutor = selectedExecutors.length === 0 || selectedExecutors.includes(task.executor_id);
    const matchesStatus = selectedStatuses.length === 0 || selectedStatuses.includes(task.status);
    const matchesTags = selectedTags.length === 0 || selectedTags.includes(task.tag);

    const matchesFilters = matchesSearch && matchesTitle && matchesAssigner && matchesExecutor && matchesStatus && matchesTags;

    let filteredSubtasks = task.subtasks.map(filterTasksRecursively).filter(Boolean);

    if (filteredSubtasks.length > 0 || matchesFilters) {
      return { ...task, subtasks: filteredSubtasks };
    }

    return null;
  };

  const filteredTasks = tasksWithSubtasks.map(filterTasksRecursively).filter(Boolean);

  const hasActiveFilters =
    searchTerm ||
    searchTermModal ||
    selectedAssigners.length > 0 ||
    selectedExecutors.length > 0 ||
    selectedStatuses.length > 0 ||
    selectedTags.length > 0;

  const renderTaskRow = (task, level = 0) => (
    <React.Fragment key={task.id}>
      <tr 
        className={`task-wrapper level-${level}`} 
        onClick={() => level === 0 ? openModal(task) : openModal(task, true)}
      >

        <td className={`task-cell title level-${level}`}>
          <div className='button-task-title'>
            <div className="icon-container" onClick={(event) => event.stopPropagation()}>
              {task.subtasks.length > 0 && !hasActiveFilters && (
                <button className="button-subtask" onClick={() => toggleSubtasks(task.id)}>
                  {expandedTasks[task.id] ? '-' : '+'}
                </button>
              )}
            </div>
            <p className='task-cell-title'>{task.title}</p>
          </div>
        </td>
        <td className="task-cell updated-at">
          {task.updated_at
            ? `${new Date(task.updated_at).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })}, 
               ${new Date(task.updated_at).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
            : 'Дата не указана'}
        </td>
        <td className="task-cell assigner">{getUserNameById(task.assigner_id, users)}</td>
        <td className="task-cell executor">{getUserNameById(task.executor_id, users)}</td>
        <td className="task-cell tag">{task.tag}</td>
      </tr>
      {(hasActiveFilters || expandedTasks[task.id]) && task.subtasks.map(subtask => renderTaskRow(subtask, level + 1))}
    </React.Fragment>
  );

  return (
    <div>
      <div className='tasks-wrapper'>
      
        <table className='tasks-wrapper-table'>
          <thead>
            <tr className="task-header">
              <th className={`task-sort title-sort ${sortConfig.key === 'title' ? 'active-sort' : ''}`} onClick={() => sortTasks('title')}>
                Название {sortConfig.key === 'title' && <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>}
              </th>
              <th className={`task-sort updated-sort ${sortConfig.key === 'updated_at' ? 'active-sort' : ''}`} onClick={() => sortTasks('updated_at')}>
                Активность {sortConfig.key === 'updated_at' && <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>}
              </th>
              <th className={`task-sort assigner-sort ${sortConfig.key === 'assigner_id' ? 'active-sort' : ''}`} onClick={() => sortTasks('assigner_id')}>
                Постановщик {sortConfig.key === 'assigner_id' && <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>}
              </th>
              <th className={`task-sort executor-sort ${sortConfig.key === 'executor_id' ? 'active-sort' : ''}`} onClick={() => sortTasks('executor_id')}>
                Исполнитель {sortConfig.key === 'executor_id' && <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>}
              </th>
              <th className={`task-sort tag-sort ${sortConfig.key === 'tag' ? 'active-sort' : ''}`} onClick={() => sortTasks('tag')}>
                Тэг {sortConfig.key === 'tag' && <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>}
              </th>
            </tr>
          </thead>
          <tbody className="task-list">
            {filteredTasks.filter(task => task.project_id === selectedProjectId).map(task => renderTaskRow(task))}
          </tbody>
        </table>
      </div>
    </div>
    
  );
};

export default TasksList;
