import React, { useState, useRef, useEffect } from "react";
import '../styles/AutocompleteInput.css';

const AutocompleteInput = ({ options, selectedItems, onChange, label, displayField = "name", isMulti = false, isDisabled }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Фильтрация опций по введённому тексту
    const filteredOptions = options.filter(
        (option) =>
            !selectedItems.includes(option.id) &&
            option[displayField].toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Добавление элемента
    const handleAddItem = (item) => {
        let updatedItems;
        if (isMulti) {
            updatedItems = [...selectedItems, item.id]; // Добавляем новый элемент
        } else {
            updatedItems = [item.id]; // Заменяем текущий элемент
        }
        onChange(updatedItems);
        setSearchTerm(""); // Очищаем поле ввода
        setIsDropdownOpen(false); // Закрываем выпадающий список
    };

    // Удаление элемента
    const handleRemoveItem = (id) => {
        const updatedItems = selectedItems.filter((itemId) => itemId !== id);
        onChange(updatedItems);
    };

    return (
        <div className="autocomplete-input" ref={wrapperRef}>
            <label className="task-panel__label">{label}</label>

            {/* Плитки выбранных элементов */}
            <div className="chips">
                {selectedItems.map((id) => {
                    const item = options.find((option) => option.id === id);
                    return (
                        <div key={id} className="chip">
                            <p>{item?.[displayField] || `Элемент ${id}`}</p>
                            <button onClick={() => handleRemoveItem(id)}>×</button>
                        </div>
                    );
                })}
            </div>

            {/* Поле ввода */}
            <input
                type="text"
                placeholder={`Введите ${label.toLowerCase()}`}
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setIsDropdownOpen(true);
                }}
                onFocus={() => setIsDropdownOpen(true)}
                className="input-place"
                disabled={isDisabled}
            />

            {/* Выпадающий список с подходящими вариантами */}
            {isDropdownOpen && filteredOptions.length > 0 && (
                <ul className="dropdown">
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            onClick={() => handleAddItem(option)}
                        >
                            {option[displayField]}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutocompleteInput;