import React, { useState } from 'react';
import './styles/KanbanBoard.css';

function KanbanBoard({ 
    tasks, 
    updateTaskStatus, 
    searchTerm, 
    selectedStatuses, 
    selectedAssigners, 
    selectedExecutors,
    selectedTags, 
    getUserNameById, 
    users 
}) {
    const statuses = [
        { id: 'new', title: 'Новая задача' },
        { id: 'in_progress', title: 'В работе' },
        { id: 'paused', title: 'На паузе' },
        { id: 'deferred', title: 'Отложена' },
        { id: 'closed', title: 'Завершена' },
    ];

    // Состояние для хранения перетаскиваемой задачи
    const [draggedTask, setDraggedTask] = useState(null);

    // При начале перетаскивания запоминаем задачу
    const onDragStart = (e, task) => {
        setDraggedTask(task);
    };

    // Разрешаем сброс элемента в колонку
    const onDragOver = (e) => {
        e.preventDefault();
    };

    // При сбросе в колонку обновляем статус задачи
    const onDrop = (e, statusId) => {
        e.preventDefault();
        if (draggedTask && draggedTask.status !== statusId) {
            updateTaskStatus(draggedTask, statusId);
        }
        setDraggedTask(null);
    };

    // Фильтрация задач
    const filterTasks = (task) => {
        const assignerName = getUserNameById(task.assigner_id, users);
        const executorName = getUserNameById(task.executor_id, users);
        const searchLower = searchTerm ? searchTerm.toLowerCase() : '';

        const matchesSearch =
            task.title.toLowerCase().includes(searchLower) ||
            (assignerName && assignerName.toLowerCase().includes(searchLower)) ||
            (executorName && executorName.toLowerCase().includes(searchLower));

        const matchesAssigner = selectedAssigners.length === 0 || selectedAssigners.includes(task.assigner_id);
        const matchesExecutor = selectedExecutors.length === 0 || selectedExecutors.includes(task.executor_id);
        const matchesStatus = selectedStatuses.length === 0 || selectedStatuses.includes(task.status);
        const matchesTags = selectedTags.length === 0 || selectedTags.includes(task.tag);

        return matchesSearch && matchesAssigner && matchesExecutor && matchesStatus && matchesTags;
    };

    return (
        <div className="kanban-board">
            {statuses.map((status) => (
                <div
                    key={status.id}
                    className="kanban-column"
                    onDragOver={onDragOver}
                    onDrop={(e) => onDrop(e, status.id)}
                >
                    <h2>{status.title}</h2>
                    {tasks
                        .filter((task) => task.status === status.id) // Оставляем только задачи этого статуса
                        .filter(filterTasks) // Применяем фильтры
                        .map((task) => (
                            <div
                                key={task.id}
                                className="kanban-card"
                                draggable
                                onDragStart={(e) => onDragStart(e, task)}
                            >
                                <p>{task.title}</p>
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
}

export default KanbanBoard;
