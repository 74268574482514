import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from './modules/axiosInstance';
import './styles/TopPage.css';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru'; // импортируем русскую локаль

// Регистрируем локаль для использования
registerLocale('ru', ru);
setDefaultLocale('ru');

const getDefaultDates = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  
  const monday = new Date(today);
  monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

  const endOfDay = (date) => {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    return d;
  };

  const formatDate = date => date.toISOString().split('T')[0];

  return {
    startOfWeek: formatDate(monday),
    today: formatDate(endOfDay(today)),
  };
};

function WeeklyStats({ user }) {
  const { startOfWeek, today } = getDefaultDates();

  const [weeklyStats, setWeeklyStats] = useState({});
  const [statistics, setStatistics] = useState([]);
  const [errorMessage] = useState('');
  const [nonEmptyColumns, setNonEmptyColumns] = useState([]);

  const [startDate, setStartDate] = useState(new Date(startOfWeek));
  const [endDate, setEndDate] = useState(new Date(today));
  const [appliedStartDate, setAppliedStartDate] = useState(new Date(startOfWeek));
  const [appliedEndDate, setAppliedEndDate] = useState(new Date(today));
  
  const [customPeriod, setCustomPeriod] = useState(false); // Для отслеживания, выбирается ли пользовательский период

  const fetchWeeklyStats = useCallback(async () => {
    try {
      const response = await axios.get('/weekly-stats', {
        params: { 
          userId: user.id, 
          startDate: appliedStartDate.toISOString().split('T')[0],
          endDate: appliedEndDate.toISOString().split('T')[0] + 'T23:59:59'
        }
      });
      setWeeklyStats(response.data);
    } catch (error) {
      toast.error('Ошибка при получении статистики за неделю');
    }
  }, [user.id, appliedStartDate, appliedEndDate]);

  const fetchStatistics = useCallback(async () => {
    if (!user.user_group) {
      console.warn("User group is undefined, skipping fetchStatistics.");
      return;
    }

    try {
      const response = await axios.get('/statistics');
      const allData = response.data;
      let filteredData = [];
      if (user.user_group === 'Admin') {
        const adminStartIndex = allData.findIndex(row => row[0] === 'Администраторы');
        const visualStartIndex = allData.findIndex(row => row[0] === 'Визуализаторы');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Редакторы');
        filteredData = [
          ...allData.slice(adminStartIndex, visualStartIndex),
          ...allData.slice(visualStartIndex, nextSectionIndex)
        ];
      } else if (user.user_group === 'Editor') {
        const editorStartIndex = allData.findIndex(row => row[0] === 'Редакторы');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Методисты');
        filteredData = allData.slice(editorStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Methodist') {
        const methodistStartIndex = allData.findIndex(row => row[0] === 'Методисты');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Сценаристы');
        filteredData = allData.slice(methodistStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Screenwriter') {
        const screenwriterStartIndex = allData.findIndex(row => row[0] === 'Сценаристы');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Видео-Монтажеры');
        filteredData = allData.slice(screenwriterStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Montazh') {
        const montazhStartIndex = allData.findIndex(row => row[0] === 'Видео-Монтажеры');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Тестировка');
        filteredData = allData.slice(montazhStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Test') {
        const TestStartIndex = allData.findIndex(row => row[0] === 'Тестировка');
        filteredData = allData.slice(TestStartIndex);
      }

      const finalData = filteredData.map(row => row.filter((_, index) => ![0, 1, 10, 11].includes(index)));

      const nonEmptyColumns = [];
      for (let colIndex = 0; colIndex < finalData[0].length; colIndex++) {
        let hasData = false;
        for (let rowIndex = 1; rowIndex < finalData.length; rowIndex++) {
          if (finalData[rowIndex][colIndex]) {
            hasData = true;
            break;
          }
        }
        if (hasData) {
          nonEmptyColumns.push(colIndex);
        }
      }
      setNonEmptyColumns(nonEmptyColumns);
      
      setStatistics(finalData);
    } catch (error) {
      toast.error('Ошибка при загрузке общей статистики');
    }
  }, [user.user_group]);

  useEffect(() => {
    fetchWeeklyStats();
    fetchStatistics();
  }, [fetchWeeklyStats, fetchStatistics]);


  const resetToCurrentWeek = () => {
    setStartDate(new Date(startOfWeek));
    setEndDate(new Date(today));
    setAppliedStartDate(new Date(startOfWeek));
    setAppliedEndDate(new Date(today));
    setCustomPeriod(false); // Сброс состояния
  }

  const renderWeeklyTableHeaders = () => {
    return user.statuses.map((status, index) => (
      <th key={index}>{status}</th>
    ));
  };

  const renderWeeklyTableData = () => {
    return Object.keys(weeklyStats).map((userName, index) => (
      <tr key={index}>
        <td>{userName}</td>
        {user.statuses.map((status, statusIndex) => (
          <td key={statusIndex}>{weeklyStats[userName][status] || 0}</td>
        ))}
      </tr>
    ));
  };

  const renderStatisticsTableData = () => {
    return statistics.slice(1).map((row, rowIndex) => (
      <tr key={rowIndex} className={row[0] && row[0].includes('Всего') ? 'total-row' : ''}>
        {nonEmptyColumns.map(colIndex => (
          <td key={colIndex}>{row[colIndex]}</td>
        ))}
      </tr>
    ));
  };

// Теперь isCustomPeriod зависит только от применённых дат
const isCustomPeriod = 
  appliedStartDate instanceof Date &&
  !isNaN(appliedStartDate.getTime()) &&
  appliedEndDate instanceof Date &&
  !isNaN(appliedEndDate.getTime()) &&
  (appliedStartDate.toISOString().split('T')[0] !== startOfWeek || 
   appliedEndDate.toISOString().split('T')[0] !== today);


const [dateError, setDateError] = useState(""); // Храним ошибку даты

const validateDates = (start, end) => {
  if (!start || !end) {
    return "Выберите обе даты.";
  }
  if (start > end) {
    return "Начальная дата не может быть позже конечной.";
  }
  return "";
};

const applyDateFilter = () => {
  const error = validateDates(startDate, endDate);
  if (error) {
    setDateError(error);
    return;
  }
  setDateError(""); // Сбрасываем ошибку
  setAppliedStartDate(startDate);
  setAppliedEndDate(endDate);
  setCustomPeriod(false); // Скрываем выбор периода
};

const buttonRef = useRef(null);
const popupRef = useRef(null);

  // Закрытие окна при клике вне его
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setCustomPeriod(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="stats-page">
      <div className='title-weekly' style={{ position: "relative" }}>
        <h2 style={{ margin: "0" }}>
          Статистика{" "}
          {isCustomPeriod
            ? `с ${startDate ? startDate.toLocaleDateString() : "..."}` +
              ` по ${endDate ? endDate.toLocaleDateString() : "..."}`
            : "за неделю"}
        </h2>

        <div className="period-buttons">
        <button
          ref={buttonRef}  // Привязываем ссылку на кнопку
          style={{ backgroundColor: "transparent", padding: "0", display: "flex" }}
          onClick={() => setCustomPeriod(prev => !prev)}
        >
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                width="24" 
                height="24" 
                fill="currentColor"
            >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2" stroke="currentColor" strokeWidth="2" fill="none"/>
                <line x1="16" y1="2" x2="16" y2="6" stroke="currentColor" strokeWidth="2"/>
                <line x1="8" y1="2" x2="8" y2="6" stroke="currentColor" strokeWidth="2"/>
                <line x1="3" y1="10" x2="21" y2="10" stroke="currentColor" strokeWidth="2"/>
                <circle cx="7" cy="14" r="1.5" fill="currentColor"/>
                <circle cx="12" cy="14" r="1.5" fill="currentColor"/>
                <circle cx="17" cy="14" r="1.5" fill="currentColor"/>
                <circle cx="7" cy="18" r="1.5" fill="currentColor"/>
                <circle cx="12" cy="18" r="1.5" fill="currentColor"/>
                <circle cx="17" cy="18" r="1.5" fill="currentColor"/>
            </svg>
          </button>
          {/* Показываем кнопку только если выбран кастомный период */}
          {isCustomPeriod && (
            <button style={{backgroundColor:"transparent", color:"black", padding:"0"}} onClick={resetToCurrentWeek}>Сбросить</button>
          )}
          </div>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {customPeriod && (
            <div ref={popupRef} className="date-filter-popup">
            <DatePicker
              selected={startDate}
              onChange={dates => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              weekStartsOn={1}
              locale={ru}
            />
            <div>
              <input
                type="date"
                value={startDate ? startDate.toISOString().split('T')[0] : ''}
                onChange={e => {
                  const inputValue = e.target.value;
                
                  // Проверяем, что введенная дата соответствует формату "YYYY-MM-DD"
                  const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(inputValue);
                
                  if (isValidFormat) {
                    const newDate = new Date(inputValue);
                
                    // Проверяем, является ли дата корректной
                    if (!isNaN(newDate.getTime())) {
                      setStartDate(newDate);
                    }
                  }
                }}
                            
              />
              <span> - </span>
              <input
                type="date"
                value={endDate ? endDate.toISOString().split('T')[0] : ''}
                onChange={e => {
                  const inputValue = e.target.value;
                
                  // Проверяем, что введенная дата соответствует формату "YYYY-MM-DD"
                  const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(inputValue);
                
                  if (isValidFormat) {
                    const newDate = new Date(inputValue);
                
                    // Проверяем, является ли дата корректной
                    if (!isNaN(newDate.getTime())) {
                      setEndDate(newDate);
                    }
                  }
                }}
                            
              />
            </div>
            {dateError && <p className="error">{dateError}</p>}
            <button className="button" onClick={applyDateFilter}>Применить</button>
          </div>
        )}
      </div>

      <div className="table-container-stats">
        <table>
          <thead>
            <tr>
              <th>Пользователь</th>
              {renderWeeklyTableHeaders()}
            </tr>
          </thead>
          <tbody>
            {renderWeeklyTableData()}
          </tbody>
        </table>
      </div>

      <h2>Общая статистика</h2>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <div className="table-container-stats">
        <table>
          <thead>
            <tr>
              {nonEmptyColumns.map(colIndex => (
                <th key={colIndex}>{statistics[0][colIndex]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderStatisticsTableData()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WeeklyStats;
