import React, { useEffect, useState } from 'react';
import axios from './modules/axiosInstance';
import './styles/NewsPage.css';
import RichTextEditor from './connectables/RichTextEditor';
import Comment from './connectables/Comment';
import wsMessage from './modules/wsMessage';
import { fetchUsers } from './modules/userService';

const NewsPage = ({ user, ws }) => {
    const [users, setUsers] = useState([])
    const [news, setNews] = useState([]);
    const [newTitle, setNewTitle] = useState('');
    const [newContent, setNewContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const newsPerPage = 3;
    let currentUserId = user.id;

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get('/news');
                setNews(response.data);
            } catch (error) {
                console.error("Ошибка загрузки новостей:", error);
            }
        };
        fetchNews();
        wsMessage(ws, fetchNews);
    }, [ws, user.id]);

    useEffect(() => {
        fetchUsers(setUsers);
    }, [])

    const addNews = async () => {
        try {
            const response = await axios.post('/news', { title: newTitle, content: newContent, userId: currentUserId });
            setNews((prevNews) => {
                if (prevNews.some(newsItem => newsItem.id === response.data.id)) {
                    return prevNews;
                }
                return [response.data, ...prevNews];
            });
            setNewTitle('');
            setNewContent('');
        } catch (error) {
            console.error("Ошибка при добавлении новости:", error);
        }
    };

    const deleteNews = async (newsId) => {
        try {
            await axios.delete(`/news/${newsId}`, { data: { userId: currentUserId } });
            setNews(prevNews => prevNews.filter(news => news.id !== newsId));
        } catch (error) {
            console.error("Ошибка при удалении новости:", error);
        }
    };

    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;

    const currentNews = news
        .slice()
        .sort((a, b) => b.id - a.id)
        .slice(indexOfFirstNews, indexOfLastNews);
    const totalPages = Math.ceil(news.length / newsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [isCreating, setIsCreating] = useState(false);
    const toggleCreateNews = () => {
        setIsCreating(prev => !prev);
    }

    return (
        <div className='news-container'>
            {user.role === 'manager' && (
                <button className="button" onClick={toggleCreateNews} style={{ marginBottom: '15px' }}>
                    {isCreating ? 'Отмена' : 'Создать новость'}
                </button>
            )}
            {isCreating && user.role === 'manager' && (
                <div className='news-wrapper'>
                    <h2>Добавить новость</h2>
                    <input className='news-creation'
                        type="text"
                        placeholder="Заголовок"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                    /><br />
                    <RichTextEditor
                        newContent={newContent}
                        setNewContent={setNewContent}
                    /><br />
                    <button className="button" onClick={addNews}>Добавить новость</button>
                </div>
            )}
            <div className={`${user.role === 'manager' ? 'news-container__bottom' : ''}`}>
                {currentNews.map(item => (
                    <div className="news-item" key={item.id}>
                        <div className='news-item__wrapper'>
                            <div className='news-item__title-wrapper'>
                                <h2 className="news-title">{item.title}</h2>
                                <span className="timestamp">
                                    {new Date(item.timestamp).getFullYear() === new Date().getFullYear()
                                        ? new Date(item.timestamp).toLocaleString('ru-RU', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })
                                        : new Date(item.timestamp).toLocaleString('ru-RU', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })
                                    }
                                </span>
                                {user.role === 'manager' && (
                                    <button onClick={() => deleteNews(item.id)} className="delete-button">
                                        🗑️
                                    </button>
                                )}
                            </div>
                            <div
                                className="news-content"
                                dangerouslySetInnerHTML={{ __html: item.content }}
                            >

                            </div>
                        </div>
                        <Comment
                            entity={item}
                            entityType="news"
                            currentUser={currentUserId}
                            users={users}
                            ws={ws}
                            user={user}
                        />
                    </div>
                ))}
                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={page === currentPage ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NewsPage;
