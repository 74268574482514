import React from 'react';

const SubtasksList = ({
    activeTask,
    openModal,
    users,
    getUserNameById,
    sortSubtasks,
    subtaskSortConfig }) => {
  return (
    <div className="subtask-wrapper">
      <div className="subtask-wrapper-header">
        <div className="subtask-title-list">
          <h3 style={{ margin: '0' }}>Подзадачи</h3>
        </div>
        <div className="tasks-wrapper">
          <table className="tasks-wrapper-table">
            <thead className="task-header">
                <tr className="task-header">
                    <th
                        className={`task-sort ${subtaskSortConfig.key === 'title' ? 'active-sort' : ''}`}
                        onClick={() => sortSubtasks('title')}
                    >
                        Название
                        {subtaskSortConfig.key === 'title' && (
                            <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                        )}
                    </th>
                    <th
                        className={`task-sort updated-sort ${subtaskSortConfig.key === 'updated_at' ? 'active-sort' : ''}`}
                        onClick={() => sortSubtasks('updated_at')}
                    >
                        Активность
                        {subtaskSortConfig.key === 'updated_at' && (
                            <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                        )}
                    </th>
                    <th
                        className={`task-sort assigner-sort ${subtaskSortConfig.key === 'assigner_id' ? 'active-sort' : ''}`}
                        onClick={() => sortSubtasks('assigner_id')}
                    >
                        Постановщик
                        {subtaskSortConfig.key === 'assigner_id' && (
                            <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                        )}
                    </th>
                    <th
                        className={`task-sort executor-sort ${subtaskSortConfig.key === 'executor_id' ? 'active-sort' : ''}`}
                        onClick={() => sortSubtasks('executor_id')}
                    >
                        Исполнитель
                        {subtaskSortConfig.key === 'executor_id' && (
                            <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                        )}
                    </th>
                    <th
                        className={`task-sort tag-sort ${subtaskSortConfig.key === 'tag' ? 'active-sort' : ''}`}
                        onClick={() => sortSubtasks('tag')}
                    >
                        Тэг
                        {subtaskSortConfig.key === 'tag' && (
                            <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                        )}
                    </th>
                </tr>
            </thead>

            <tbody className="subtasks">
              {activeTask.subtasks.map((subtask) => (
                <tr key={subtask.id} className="subtask-row">
                  <td className="task-cell title subtask" onClick={() => openModal(subtask, true)}>
                    {subtask.title}
                  </td>
                  <td className="task-cell updated-at subtask">
                    {subtask.updated_at
                      ? `${new Date(subtask.updated_at).toLocaleDateString('ru-RU', {
                          day: 'numeric',
                          month: 'long',
                        })}, ${new Date(subtask.updated_at).toLocaleTimeString('ru-RU', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}`
                      : 'Дата не указана'}
                  </td>
                  <td className="task-cell assigner subtask">{getUserNameById(subtask.assigner_id, users)}</td>
                  <td className="task-cell executor subtask">{getUserNameById(subtask.executor_id, users)}</td>
                  <td className="task-cell tag subtask">{subtask.tag}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubtasksList;