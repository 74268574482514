import React, { useState } from 'react';
import axios from 'axios';

function ChangeLog() {
  const [changeLog, setChangeLog] = useState([]);
  const [isChangeLog, setIsChangeLog] = useState(false);
  const [queueWaiting, setQueueWaiting] = useState([]);
  const [queueActive, setQueueActive] = useState([]);
  const [queueFailed, setQueueFailed] = useState([]);
  const [queueCompleted, setQueueCompleted] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [activeTab, setActiveTab] = useState('');

  const fetchChangeLog = async () => {
    try {
      const response = await axios.get('/changelog');
      setChangeLog(response.data);
      setIsChangeLog(true);
    } catch (err) {
      console.error('Ошибка:', err);
    }
  };

  const fetchQueueCompleted = async () => {
    try {
      const response = await axios.get('/queue-completed');
      if (!response.data.completed?.length) return setQueueCompleted([]);
      setQueueCompleted(response.data.completed);
    } catch (err) {
      console.error('Ошибка загрузки очереди:', err);
    }
  };

  const fetchQueueWaiting = async () => {
    try {
      const response = await axios.get('/queue-waiting');
      if (!response.data.waiting?.length) return setQueueWaiting([]);
      setQueueWaiting(response.data.waiting);
    } catch (err) {
      console.error('Ошибка загрузки очереди:', err);
    }
  };
  const fetchQueueActive = async () => {
    try {
      const response = await axios.get('/queue-active');
      if (!response.data.active?.length) return setQueueActive([]);

      setQueueActive(response.data.active);
    } catch (err) {
      console.error('Ошибка загрузки очереди:', err);
    }
  };
  const fetchQueueFailed = async () => {
    try {
      const response = await axios.get('/queue-failed');
      if (!response.data.failed?.length) return setQueueFailed([]);

      setQueueFailed(response.data.failed);
    } catch (err) {
      console.error('Ошибка загрузки очереди:', err);
    }
  };

  const handleTabClick = (tab, fetchFunction) => {
    setActiveTab((prev) => (prev === tab ? '' : tab));
    fetchFunction();
  };

  return (
    <div className="change-log">
      <h3 className='change-log__title'>Журнал изменений и очередь задач</h3>
      <div className='button-container'>
        <div className='button-wrapper'>
          <button
            className='button'
            onClick={!isChangeLog ? fetchChangeLog : () => setIsChangeLog(false)}
          >
            {!isChangeLog ? 'Загрузить журнал изменений' : 'Скрыть журнал изменений'}
          </button>

          <button
            className='button'
            onClick={() => {
              setShowButton(!showButton);
              if (showButton) setActiveTab('');
            }}
          >
            {!showButton ? 'Показать очередь' : 'Скрыть очередь'}
          </button>
        </div>
        {showButton && (
          <div className='button-wrapper'>
            <button
              className='button'
              onClick={() => handleTabClick('waiting', fetchQueueWaiting)}
            >
              Ожидают обработки
            </button>
            <button
              className='button'
              onClick={() => handleTabClick('active', fetchQueueActive)}
            >
              Выполняются
            </button>
            <button
              className='button'
              onClick={() => handleTabClick('failed', fetchQueueFailed)}
            >
              Ошибки
            </button>
            <button
              className='button'
              onClick={() => handleTabClick('completed', fetchQueueCompleted)}
            >
              Завершенные
            </button>
          </div>
        )}
      </div>

      {isChangeLog && (
        <div className='table-container-change'>
          <table>
            <thead>
              <tr>
                <th>Пользователь</th>
                <th>ЦОК</th>
                <th>ЭОМ</th>
                <th>Тип изменения</th>
                <th>Новое значение</th>
                <th>Обновление в базе данных</th>
                <th>Обновление в гугл таблице</th>
                <th>Время</th>
              </tr>
            </thead>
            <tbody>
              {changeLog.map((log, index) => (
                <tr key={index}>
                  <td data-label="Пользователь">{log.name || 'Неизвестно'}</td>
                  <td data-label="ЦОК">{log.tsok}</td>
                  <td data-label="ЭОМ">{log.eom}</td>
                  <td data-label="Тип изменения"><b>{log.change_type}</b></td>
                  <td data-label="Новое значение"><b>{log.new_value}</b></td>
                  <td data-label="Обновление в базе данных">
                    <b>{log.is_db_updated === "1" ? "Готов" :
                      log.is_db_updated === "Ошибка" ? "Ошибка" :
                        "Загрузка"}</b>
                  </td>
                  <td data-label="Обновление в гугл таблице">
                    <b>{log.is_google_updated === "1" ? "Готов" :
                      log.is_google_updated === "Ошибка" ? "Ошибка" :
                        "Загрузка"}</b>
                  </td>
                  <td data-label="Время">{new Date(log.timestamp).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {activeTab === 'completed' && (
        queueCompleted.length > 0 ? (
          <div>
            <h4>Завершенные задачи</h4>
            <div className="table-container-change">
              <table>
                <thead>
                  <tr>
                    <th>Тип изменения</th>
                    <th>Страницы</th>
                    <th>ЦОК</th>
                    <th>Новый комментарий ЦОК</th>
                    <th>ЭОМ</th>
                    <th>Новое имя эома/Новый эом</th>
                    <th>Новый комментарий ЭОМа</th>
                    <th>Новый статус ЭОМа</th>

                  </tr>
                </thead>
                <tbody>
                  {queueCompleted.map((job, index) => {
                    const {
                      tsok,
                      eom,
                      sheet,
                      sheetsList,
                      action,
                      newTsokComment,
                      newComment,
                      newEomName,
                      newStatus,
                    } = job.data;

                    return (
                      <tr key={index}>
                        <td>{action || '-'}</td>
                        <td>{sheet || (sheetsList ? sheetsList.join(', ') : '-')}</td>
                        <td>{tsok || '-'}</td>
                        <td>{newTsokComment || '-'}</td>
                        <td>{eom || '-'}</td>
                        <td>{newEomName || '-'}</td>
                        <td>{newComment || '-'}</td>
                        <td>{newStatus || '-'}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Очередь пуста</p>
        )


      )}
      {activeTab === 'waiting' && (
        queueWaiting.length > 0 ? (
          <div>
            <h4>В ожидании</h4>
            <div className="table-container-change">
              <table>
                <thead>
                  <tr>
                    <th>Тип изменения</th>
                    <th>Страницы</th>
                    <th>ЦОК</th>
                    <th>Новый комментарий ЦОК</th>
                    <th>ЭОМ</th>
                    <th>Новое имя эома/Новый эом</th>
                    <th>Новый комментарий ЭОМа</th>
                    <th>Новый статус ЭОМа</th>

                  </tr>
                </thead>
                <tbody>
                  {queueWaiting.map((job, index) => {
                    const {
                      tsok,
                      eom,
                      sheet,
                      sheetsList,
                      action,
                      newTsokComment,
                      newComment,
                      newEomName,
                      newStatus,
                    } = job.data;

                    return (
                      <tr key={index}>
                        <td>{action || '-'}</td>
                        <td>{sheet || (sheetsList ? sheetsList.join(', ') : '-')}</td>
                        <td>{tsok || '-'}</td>
                        <td>{newTsokComment || '-'}</td>
                        <td>{eom || '-'}</td>
                        <td>{newEomName || '-'}</td>
                        <td>{newComment || '-'}</td>
                        <td>{newStatus || '-'}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Очередь пуста</p>
        )


      )}
      {activeTab === 'active' && (
        queueActive.length > 0 ? (
          <div>
            <h4>В работе</h4>
            <div className="table-container-change">
              <table>
                <thead>
                  <tr>
                    <th>Тип изменения</th>
                    <th>Страницы</th>
                    <th>ЦОК</th>
                    <th>Новый комментарий ЦОК</th>
                    <th>ЭОМ</th>
                    <th>Новое имя эома/Новый эом</th>
                    <th>Новый комментарий ЭОМа</th>
                    <th>Новый статус ЭОМа</th>

                  </tr>
                </thead>
                <tbody>
                  {queueActive.map((job, index) => {
                    const {
                      tsok,
                      eom,
                      sheet,
                      sheetsList,
                      action,
                      newTsokComment,
                      newComment,
                      newEomName,
                      newStatus,
                    } = job.data;

                    return (
                      <tr key={index}>
                        <td>{action || '-'}</td>
                        <td>{sheet || (sheetsList ? sheetsList.join(', ') : '-')}</td>
                        <td>{tsok || '-'}</td>
                        <td>{newTsokComment || '-'}</td>
                        <td>{eom || '-'}</td>
                        <td>{newEomName || '-'}</td>
                        <td>{newComment || '-'}</td>
                        <td>{newStatus || '-'}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Очередь пуста</p>
        )


      )}
      {activeTab === 'failed' && (
        queueFailed.length > 0 ? (
          <div>
            <h4>Завершенные с ошибкой</h4>
            <div className="table-container-change">
              <table>
                <thead>
                  <tr>
                    <th>Тип изменения</th>
                    <th>Страницы</th>
                    <th>ЦОК</th>
                    <th>Новый комментарий ЦОК</th>
                    <th>ЭОМ</th>
                    <th>Новое имя эома/Новый эом</th>
                    <th>Новый комментарий ЭОМа</th>
                    <th>Новый статус ЭОМа</th>
                    <th>Причина ошибки</th>
                  </tr>
                </thead>
                <tbody>
                  {queueFailed.map((job, index) => {
                    const {
                      tsok,
                      eom,
                      sheet,
                      sheetsList,
                      action,
                      newTsokComment,
                      newComment,
                      newEomName,
                      newStatus,
                    } = job.data;

                    return (
                      <tr key={index}>
                        <td>{action || '-'}</td>
                        <td>{sheet || (sheetsList ? sheetsList.join(', ') : '-')}</td>
                        <td>{tsok || '-'}</td>
                        <td>{newTsokComment || '-'}</td>
                        <td>{eom || '-'}</td>
                        <td>{newEomName || '-'}</td>
                        <td>{newComment || '-'}</td>
                        <td>{newStatus || '-'}</td>
                        <td>{job.failedReason}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Очередь пуста</p>
        )


      )}
    </div>
  );
}

export default ChangeLog;
