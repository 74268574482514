import '../styles/Workspace.css'
import RichTextEditor from './RichTextEditor';
import React, { useEffect, useCallback } from 'react';
import AutocompleteInput from "./AutocompleteInput"

function CreateTaskForm({
    user,
    newTask,
    setNewTask,
    users,
    tasks,
    projects,
    addTask,
    activeTask,
    setShowPan,
    fetchSubtasks,
    template,
    setTemplate,
}) {

    const completeAddTask = () => {
        addTask();
        if (activeTask) {
            fetchSubtasks(activeTask.id);
            setTemplate(null);
        }
        setShowPan(false);
    };

    const resetTask = useCallback(() => {
        setNewTask({
            title: '',
            description: '',
            assigner_id: null,
            executor_id: null,
            observers: [],
            project_id: null,
            parent_task_id: null,
            tag: '',
            deadline: null,
            important: 0,
        });
    }, [setNewTask]);

    useEffect(() => {
        return () => {
            if (activeTask) {
                setTemplate(null);
            }
            resetTask();
        };
    }, [activeTask, setTemplate, resetTask]); // Добавляем зависимости

    if (activeTask && user) {
        newTask.assigner_id = user.id;
        newTask.parent_task_id = activeTask.id;
        newTask.project_id = activeTask.project_id || '';
    }

    const closeModal = () => {
        setShowPan(false);
    }

    return (
        <div className='modal-overlay' onClick={closeModal}>
            <div className='modal-wrapper' onClick={(e) => e.stopPropagation()}>
                <div className='close-wrapper'>
                    <p class="task-detail-subtitle-status ">Задача № {(activeTask)?.id || 'ID отсутствует'} - <span>{'Статус отсутствует'}</span></p>
                    <span className="close-button" onClick={closeModal}>
                        &times;
                    </span>
                </div>
                <div className="modal-wrapper__bottom">
                    <div className="description-comment">
                        <div className='task-detail-header'>
                            <label className='task-panel__label task-panel__label-title'>
                                {activeTask ? 'Название подзадачи' : 'Название задачи'}
                                <input
                                    className='task-panel__input'
                                    type="text"
                                    value={newTask.title || template?.name}
                                    onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                                />
                            </label>
                            <div
                                id="task-detail-important-button"
                                className="task-info-panel-important mutable"
                                onClick={() => setNewTask({ ...newTask, important: newTask?.important ? 0 : 1 })}
                            >
                                {newTask?.important ? 'Это важная задача 🔥' : 'Сделать важной задачей'}
                            </div>
                        </div>
                        <div className='description-section'>
                            <label className='task-panel__label task-panel__label-desc'>
                                {activeTask ? "Описание подзадачи" : "Описание задачи"}
                                <RichTextEditor
                                    newContent={newTask.description || template?.description}
                                    setNewContent={(content) => setNewTask({ ...newTask, description: content })}
                                />
                            </label>
                        </div>
                        <div className='check-list-wrapper'>
                            <p>+ добавить чек-лист</p>
                        </div>
                        <div className='people-wrapper'>
                            {activeTask ? (
                                <label className='task-panel__label task-panel__label-assigner'>
                                    {/* Постановщик: */}
                                    <AutocompleteInput
                                        options={users}
                                        selectedItems={newTask.assigner_id ? [newTask.assigner_id] : []}
                                        onChange={(updatedItems) => {
                                        }}
                                        label="Постановщик"
                                        displayField="name"
                                        isMulti={false}
                                        isDisabled={true}
                                    />
                                </label>
                            ) : (
                                <AutocompleteInput
                                    options={users}
                                    selectedItems={newTask.assigner_id ? [newTask.assigner_id] : []}
                                    onChange={(updatedItems) =>
                                        setNewTask({ ...newTask, assigner_id: updatedItems[0] || null })
                                    }
                                    label="Постановщик"
                                    displayField="name"
                                    isMulti={false}
                                />
                            )}

                            <AutocompleteInput
                                options={users}
                                selectedItems={newTask.executor_id ? [newTask.executor_id] : []}
                                onChange={(updatedItems) =>
                                    setNewTask({ ...newTask, executor_id: updatedItems[0] || null })
                                }
                                label="Исполнитель"
                                displayField="name"
                                isMulti={false}

                            />

                            <AutocompleteInput
                                options={users}
                                selectedItems={newTask.observers || []}
                                onChange={(updatedItems) =>
                                    setNewTask({ ...newTask, observers: updatedItems })
                                }
                                label="Наблюдатели"
                                displayField="name"
                                isMulti={true} // Включаем множественный выбор
                            />

                        </div>

                        <div className='main-data-wrapper'>
                            {activeTask ? (
                                <label className='task-panel__label task-panel__label-project'>
                                    {/* Проект: */}
                                    <AutocompleteInput
                                        options={projects}
                                        selectedItems={newTask.project_id ? [newTask.project_id] : []}
                                        onChange={(updatedItems) => {
                                            // В режиме activeTask проект не может быть изменен
                                        }}
                                        label="Проект"
                                        displayField="name"
                                        isMulti={false}
                                        isDisabled={true} // Отключаем редактирование
                                    />
                                </label>
                            ) : (
                                <AutocompleteInput
                                    options={projects}
                                    selectedItems={newTask.project_id ? [newTask.project_id] : []}
                                    onChange={(updatedItems) =>
                                        setNewTask({ ...newTask, project_id: updatedItems[0] || null })
                                    }
                                    label="Проект"
                                    displayField="name"
                                    isMulti={false}
                                />
                            )}

                            {activeTask ? (
                                <label className='task-panel__label task-panel__label-parent'>
                                    {/* Основная задача: */}
                                    <AutocompleteInput
                                        options={tasks}
                                        selectedItems={newTask.parent_task_id ? [newTask.parent_task_id] : []}
                                        onChange={(updatedItems) => {
                                            // В режиме activeTask основная задача не может быть изменена
                                        }}
                                        label="Основная задача"
                                        displayField="title"
                                        isMulti={false}
                                        isDisabled={true} // Отключаем редактирование
                                    />
                                </label>
                            ) : (
                                <AutocompleteInput
                                    options={tasks}
                                    selectedItems={newTask.parent_task_id ? [newTask.parent_task_id] : []}
                                    onChange={(updatedItems) =>
                                        setNewTask({ ...newTask, parent_task_id: updatedItems[0] || null })
                                    }
                                    label="Основная задача"
                                    displayField="title"
                                    isMulti={false}
                                />
                            )}
                        </div>

                        <div className='secondary-data-wrapper'>

                            <label className='task-panel__label task-panel__label-tags'>
                                Теги
                                <input
                                    className='task-panel__input'
                                    type="text"
                                    placeholder="Тэг"
                                    value={newTask.tag || template?.tag}
                                    onChange={(e) => setNewTask({ ...newTask, tag: e.target.value })}
                                />
                            </label>

                            <label className='task-panel__label task-panel__label-deadline'>
                                Дедлайн
                                <input
                                    className='task-panel__input'
                                    type='datetime-local'
                                    value={newTask.deadline || ''}
                                    onChange={(e) => {
                                        const value = e.target.value || null;
                                        setNewTask({ ...newTask, deadline: value })
                                    }}
                                />
                            </label>
                        </div>
                        <div className='task-panel__button'>
                            {activeTask ? (
                                <button className='button' onClick={() => completeAddTask()}>Сохранить задачу</button>
                            ) : (
                                <button className='button' onClick={() => completeAddTask()}>Сохранить задачу</button>
                            )}
                        </div>
                    </div >
                </div>
            </div>
        </div>
    )
}

export default CreateTaskForm;
