import axios from 'axios';

const axiosInstance = axios.create();

// Добавляем интерсептор запросов
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Обрабатываем ответы для проверки статуса авторизации
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            const originalRequest = error.config;

            // Проверяем, был ли запрос на /login (если да, не разлогиниваем)
            if (originalRequest.url.includes('/login')) {
                return Promise.reject(error);
            }

            // При всех остальных 401 - разлогиниваем пользователя
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            window.location.href = '/'; // Перенаправляем на страницу логина
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
