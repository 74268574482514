import React, { useState } from "react";
import "../styles/CreateProjectModal.css"; // Подключаем стили

function CreateProjectModal({ onClose, onCreate, user }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = () => {
        if (!name.trim()) {
            setError("Название проекта не может быть пустым");
            return;
        }
        setError("");

        onCreate({ name, description, owner_id: user.id });
        onClose();
    };

    const handleOverlayClick = (e) => {
        if (e.target.id === "modal-cr-project") {
            onClose();
        }
    };

    return (
        <div id="modal-cr-project" className="modal-cr-project" onClick={handleOverlayClick}>
            <div className="modal-cr-project-content">
                <button onClick={onClose} className="close-button">×</button>
                <h2 className="modal-title">Создание проекта</h2>
                
                <input
                    type="text"
                    placeholder="Название проекта"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="modal-input"
                />

                <textarea
                    placeholder="Описание проекта"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="modal-textarea"
                />

                {error && <p className="modal-error">{error}</p>}

                <button onClick={handleSubmit} className="button">
                    Создать
                </button>
            </div>
        </div>
    );
}

export default CreateProjectModal;
