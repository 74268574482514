import React, { useState, useEffect, useRef } from 'react';
import AutocompleteInput from '../connectables/AutocompleteInput';
import '../styles/SearchFilter.css';

const SearchFilter = ({
  users,
  onFiltersChange,
  tags,
  searchTerm,
  searchTermModal,
  selectedAssigners,
  selectedExecutors,
  selectedStatuses,
  selectedTags
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const [localSearchTermModal, setLocalSearchTermModal] = useState(searchTermModal);
  const [localSelectedAssigners, setLocalSelectedAssigners] = useState(selectedAssigners);
  const [localSelectedExecutors, setLocalSelectedExecutors] = useState(selectedExecutors);
  const [localSelectedStatuses, setLocalSelectedStatuses] = useState(selectedStatuses);
  const [localSelectedTags, setLocalSelectedTags] = useState(selectedTags);

  useEffect(() => {
    setLocalSearchTermModal(searchTermModal);
    setLocalSelectedAssigners(selectedAssigners);
    setLocalSelectedExecutors(selectedExecutors);
    setLocalSelectedStatuses(selectedStatuses);
    setLocalSelectedTags(selectedTags);
}, [searchTermModal, selectedAssigners, selectedExecutors, selectedStatuses, selectedTags]);


  const buttonRef = useRef(null);

  const toggleModal = () => {
    if (!isModalOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.bottom + window.scrollY + 5,
        left: rect.right + window.scrollX,
      });
    }
    setIsModalOpen(!isModalOpen);
  };

  const statusTranslations = {
    paused: 'Приостановлено',
    new: 'Новый',
    deferred: 'Отложено',
    closed: 'Завершен',
    in_progress: 'В процессе',
  };

  const taskStatuses = ['paused', 'new', 'deferred', 'closed', 'in_progress'];

  // Функции обработки изменений
  const handleStatusChange = (status) => {
    setLocalSelectedStatuses((prevStatuses) =>
      prevStatuses.includes(status)
        ? prevStatuses.filter((item) => item !== status)
        : [...prevStatuses, status]
    );
  };

  const handleAssignerChange = (updatedAssigners) => {
    setLocalSelectedAssigners(updatedAssigners);
  };

  const handleExecutorChange = (updatedExecutors) => {
    setLocalSelectedExecutors(updatedExecutors);
  };

  const handleTagChange = (tag) => {
    setLocalSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((item) => item !== tag)
        : [...prevTags, tag]
    );
  };

  // Обработчик для применения фильтров
  const handleApplyFilters = () => {
    onFiltersChange({
      searchTerm,
      searchTermModal: localSearchTermModal,
      selectedAssigners: localSelectedAssigners,
      selectedExecutors: localSelectedExecutors,
      selectedStatuses: localSelectedStatuses,
      selectedTags: localSelectedTags,
    });
    toggleModal();
  };

  const handleResetFilters = () => {
    setLocalSearchTermModal('');
    setLocalSelectedAssigners([]);
    setLocalSelectedExecutors([]);
    setLocalSelectedStatuses([]);
    setLocalSelectedTags([]);

    onFiltersChange({
      searchTerm: '',
      searchTermModal: '',
      selectedAssigners: [],
      selectedExecutors: [],
      selectedStatuses: [],
      selectedTags: [],
    });
  };

  // Проверка на наличие активных фильтров
  const hasActiveFilters =
    searchTerm ||
    localSearchTermModal ||
    localSelectedAssigners.length > 0 ||
    localSelectedExecutors.length > 0 ||
    localSelectedStatuses.length > 0 ||
    localSelectedTags.length > 0;

  return (
    <div className="filter-container">
      <input
        className="filter-input"
        type="text"
        placeholder="Поиск"
        value={searchTerm}
        onChange={(e) =>
          onFiltersChange({
            searchTerm: e.target.value,
            searchTermModal,
            selectedAssigners,
            selectedExecutors,
            selectedStatuses,
            selectedTags,
          })
        } // Только для изменения строки поиска
      />
      <button ref={buttonRef} className="button" onClick={toggleModal}>
        Фильтры
      </button>

      {hasActiveFilters && (
        <button className="button-reset" onClick={handleResetFilters}>
          Сбросить
        </button>
      )}

      {isModalOpen && (
        <div
          className="modal-filter"
          style={{ top: modalPosition.top, left: modalPosition.right }}
        >
          <div className="modal-content-filter">
            <h3 style={{ margin: '0' }}>Фильтры</h3>
            <div className="block-filter">
              <input
                type="text"
                placeholder="Введите название задачи"
                value={localSearchTermModal}
                onChange={(e) => setLocalSearchTermModal(e.target.value)} // Только для изменения текста поиска в модале
              />
            </div>

            <div className="block-filter">
              <label>Статус:</label>
              <div className="checkbox-group">
                {taskStatuses.map((status) => (
                  <label key={status} className="checkbox-label">
                    <input
                      className="input-check"
                      type="checkbox"
                      checked={localSelectedStatuses.includes(status)}
                      onChange={() => handleStatusChange(status)} // Изменение статуса
                    />
                    <span className="checkbox-text">{statusTranslations[status]}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="block-filter">
              <label>Постановщик:</label>
              <div className="autocomplete-filter">
                <AutocompleteInput
                  options={users}
                  selectedItems={localSelectedAssigners}
                  onChange={handleAssignerChange}
                  label="постановщика"
                  displayField="name"
                  isMulti={true}
                  isDisabled={false}
                />
              </div>
            </div>

            <div className="block-filter">
              <label>Исполнитель:</label>
              <div className="autocomplete-filter">
                <AutocompleteInput
                  options={users}
                  selectedItems={localSelectedExecutors}
                  onChange={handleExecutorChange}
                  label="исполнителя"
                  displayField="name"
                  isMulti={true}
                  isDisabled={false}
                />
              </div>
            </div>

            <div className="block-filter">
              <label>Тэг:</label>
              <div className="checkbox-group">
                {tags.map((tag) => (
                  <label key={tag} className="checkbox-label">
                    <input
                      className="input-check"
                      type="checkbox"
                      checked={localSelectedTags.includes(tag)}
                      onChange={() => handleTagChange(tag)}
                    />
                    <span className="checkbox-text">{tag}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="filter-button">
              <div style={{ display: 'flex', gap: '10px' }}>
                <button className="button" onClick={handleApplyFilters}>Найти</button>
                {hasActiveFilters && (
                  <button className="button-reset" onClick={handleResetFilters}>Сбросить</button>
                )}
              </div>

              <button className="button" onClick={toggleModal}>Закрыть</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
