import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from './axiosInstance';

import '../styles/UserProfile.css';

const roleTranslations = {
    user: 'Пользователь',
    manager: 'Менеджер',
    block: 'Заблокирован',
};

const UserProfile = ({ user, updateUser, isOwnProfile, currentUser }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newUsername, setNewUsername] = useState(user.username);
    const [localUser, setLocalUser] = useState(user);
    const [isEditingRole, setIsEditingRole] = useState(false);
    const [newRole, setNewRole] = useState(user.role);

    const modalClass = isOwnProfile ? 'user-profile__own' : 'user-profile__modal';

    useEffect(() => {
        setLocalUser(user);
        if (!isEditing) {
            setNewUsername(user.username); // Обновляем `newUsername` только если не редактируем
        }
        setNewRole(user.role);
    }, [user, isEditing]);

    const handleUpdateUsername = async () => {
        if (newUsername.trim() === '' || newUsername === localUser.username) {
            setIsEditing(false); // Не отправляем запрос, если логин пустой или не изменился
            return;
        }

        try {
            const response = await axiosInstance.put(`/users/${user.id}/username`, {
                username: newUsername,
            });

            if (response.status === 200) {
                // Обновляем глобальное и локальное состояние
                const updatedUser = { ...localUser, username: newUsername };
                setLocalUser(updatedUser);
                updateUser(updatedUser); // Синхронизация с глобальным состоянием
                setIsEditing(false);
                toast.success('Логин успешно обновлен!');
            }

        } catch (err) {
            // console.error('Ошибка обновления логина:', err.response ? err.response.data : err.message);
            if (err.response && err.response.status === 400) {
                toast.error("Такой логин уже существует"); // Если сервер вернул 400, показываем ошибку
            } else {
                console.error('Ошибка обновления логина:', err.response ? err.response.data : err.message);
                toast.error("Ошибка сервера");
            }
        }
    };

    const handleUpdateRole = async () => {
        if (newRole === localUser.role) {
            setIsEditingRole(false); // Не отправляем запрос, если роль не изменилась
            return;
        }

        try {
            const response = await axiosInstance.put(`/users/${user.id}/role`, {
                role: newRole,
            });

            if (response.status === 200) {
                // Обновляем глобальное и локальное состояние
                const updatedUser = { ...localUser, role: newRole };
                setLocalUser(updatedUser);
                updateUser(updatedUser); // Синхронизация с глобальным состоянием
                setIsEditingRole(false);
            }

        } catch (err) {
            console.error('Ошибка обновления роли:', err.response ? err.response.data : err.message);
        }
    };

    const handleRoleChange = (e) => {
        setNewRole(e.target.value);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setNewUsername(localUser.username); // Сбрасываем изменения
        setIsEditing(false);
    };

    return (
        <div className={modalClass}>
            <div className="user-profile__card">
                <h3 className="user-profile__name">{localUser.name}</h3>

                {/* Блок логина - виден только менеджерам или в своем профиле */}
                {(isOwnProfile || currentUser?.role === 'manager') && (
                    <div className="user-profile__login">
                        <span>Логин: </span>
                        {isEditing ? (
                            <div className="user-profile__edit">
                                <input
                                    type="text"
                                    value={newUsername}
                                    onChange={(e) => setNewUsername(e.target.value)}
                                    className="user-profile__input"
                                />
                                <button onClick={handleUpdateUsername} className="user-profile__save">✔</button>
                                <button onClick={handleCancelClick} className="user-profile__cancel">✖</button>
                            </div>
                        ) : (
                            <>
                                <p>{localUser.username}</p>
                                {isOwnProfile && (
                                    <button onClick={handleEditClick} className="user-profile__edit-btn">✏️</button>
                                )}
                            </>
                        )}
                    </div>
                )}

                <div className="user-profile__group">
                    <span>Группа: </span>
                    <p>{isOwnProfile ? localUser.user_group : localUser.group}</p>
                </div>

                <div className="user-profile__sheet">
                    <span>Лист: </span><p>{localUser.sheet}</p>
                </div>

                <div className="user-profile__status">
                    <span>Доступные статусы: </span>
                    <ul className="user-profile__status-list">
                        {(Array.isArray(localUser.statuses) ? localUser.statuses : []).map((status, index) => (
                            <li key={index} className="user-profile__status-item">
                                {status}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="user-profile__role">
                    <span>Роль: </span>
                    {isOwnProfile ? (
                        <p>{roleTranslations[localUser.role]}</p>
                    ) : (
                        isEditingRole ? (
                            <div className="user-profile__edit">
                                <select
                                    value={newRole}
                                    onChange={handleRoleChange}
                                    className="user-profile__input"
                                >
                                    <option value="user">Пользователь</option>
                                    <option value="manager">Менеджер</option>
                                </select>
                                <button onClick={handleUpdateRole} className="user-profile__save">✔</button>
                                <button onClick={() => setIsEditingRole(false)} className="user-profile__cancel">✖</button>
                            </div>
                        ) : (
                            <>
                                <p>{roleTranslations[localUser.role]}</p>
                                {currentUser.role === 'manager' && (
                                    <button onClick={() => setIsEditingRole(true)} className="user-profile__edit-btn">✏️</button>                                    
                                )}
                            </>
                        )
                    )}
                </div>

            </div>
        </div>
    );
};

export default UserProfile;
