import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tab, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';

import Login from './Login';
import SearchPage from './SearchPage';
import TopPage from './TopPage';
import WeeklyStats from './WeeklyStats';
import AccountManagement from './AccountManagement';
import NotificationManagement from './NotificationManagement';
import Notifications from './Notifications';
import NewsPage from './NewsPage';
import Workspace from './Workspace';
import UserProfile from './modules/UserProfile';

import logos from './assets/logos.svg';
import { ToastContainer } from 'react-toastify';
import { useUserApp } from './hooks/useUserApp';
import './styles/App.css';
import './styles/tabpanel.css';
import './styles/style.css';
import 'react-toastify/dist/ReactToastify.css';


function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}


function App() {
  const { user, errorMessage, login, logout, wsRef, handleTabChange, updateUser } = useUserApp();
  const [userInfo, setUserInfo] = useState(false);

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (user && location.pathname === "/") {
      navigate("/news-page", { replace: true });
    }
  }, [user, location.pathname, navigate]);


  useEffect(() => {
    const path = location.pathname;

    const pathPatterns = [
      { pattern: '/news-page', index: 0 },
      { pattern: '/search-page', index: 1 },
      { pattern: '/top', index: 2 },
      { pattern: '/stats', index: 3 },
      { pattern: '/tasks', index: 4 },
      { pattern: '/account-management', index: 5 },
      { pattern: '/notification-management', index: 6 },
    ];

    const matchedTab = pathPatterns.find(({ pattern }) => path.startsWith(pattern));

    if (matchedTab) {
      setActiveTab(matchedTab.index);
    } else {
      setActiveTab(0);
    }
  }, [location.pathname]);

  const width = useWindowWidth();
  const isMobile = width <= 600;

  const handleClickOutside = useCallback(e => {
    const modal = document.querySelector('.user-profile__own');
    const header = document.querySelector('.user-info__btn');

    if (header && header.contains(e.target)) {
      return;
    }

    if (modal && !modal.contains(e.target)) {
      setUserInfo(false);
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [userInfo, handleClickOutside]);

  const currentPath = window.location.pathname;

  const renderLoginHeader = () => {
    return (
      <div className="header" style={{ justifyContent: 'center' }}>
        <div className="logo-container">
          <img src={logos} alt="Logo" className="logo-login" />
        </div>
      </div>
    );
  };

  const renderMainHeader = () => (
    <>
      {!isMobile && (
        <div className="header">
          <div className="logo-container">
            <img src={logos} alt="Logo" className="logo" />
          </div>
          {user && (
            <>
              <div className="notifications-logout">
                <Notifications user={user} ws={wsRef.current} />
                <div className="user-profile">
                  <span className="user-info">Пользователь:
                    <button
                      className="user-info__btn"
                      onClick={() => setUserInfo(!userInfo)}>
                      {user.name}
                    </button>
                  </span>
                  {userInfo && (
                    <UserProfile
                      user={user}
                      isOwnProfile={true}
                      updateUser={updateUser}
                    />
                  )}
                </div>
                <button className="button" onClick={logout}>Выйти</button>
              </div>
            </>
          )}
        </div>
      )}

      {isMobile && (
        <div className="header">
          {user && (
            <>
              <div className="notifications-logout">
                <div className="logo-container">
                  <img src={logos} alt="Logo" className="logo" />
                </div>
                <div className='notification_mobile'>
                  <Notifications user={user} ws={wsRef.current} />
                  <button className="button" onClick={logout}>Выйти</button>
                </div>
              </div>
              <div className="user-profile">
                <span className="user-info">Пользователь:
                  <button
                    className="user-info__btn"
                    onClick={() => setUserInfo(!userInfo)}>
                    {user.name}
                  </button>
                </span>
                {userInfo && (
                  <UserProfile
                    user={user}
                    isOwnProfile={true}
                    updateUser={updateUser}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );

  return (
    <div className="App">
      <ToastContainer />
      {currentPath === '/' ? renderLoginHeader() : renderMainHeader()}
      <div className="menu">
        {!user ? (
          <Login onLogin={login} errorMessage={errorMessage} />
        ) : (
          <>
            <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
              <TabList>
                <Tab><Link to="/news-page">Новости</Link></Tab>
                <Tab><Link to="/search-page">Поиск ЦОК</Link></Tab>
                <Tab><Link to="/top">Топ ЦОКи</Link></Tab>
                <Tab><Link to="/stats">Статистика</Link></Tab>
                <Tab style={{ display: 'none' }}><Link to="/tasks">Задачи</Link></Tab>
                {user.role === 'manager' && <Tab><Link to="/account-management">Управление аккаунтами</Link></Tab>}
                {user.role === 'manager' && <Tab><Link to="/notification-management">Управление уведомлениями</Link></Tab>}
              </TabList>
            </Tabs>
            <Routes>
              <Route path="/news-page" element={<NewsPage user={user} ws={wsRef.current} />} />
              <Route path="/search-page" element={<SearchPage user={user} />} />
              <Route path="/top" element={<TopPage />} />
              <Route path="/stats" element={<WeeklyStats user={user} />} />
              <Route path="/tasks" element={<Workspace user={user} ws={wsRef.current} />} />
              <Route path="/tasks/:id" element={<Workspace user={user} ws={wsRef.current} />} />
              <Route path="/account-management" element={<AccountManagement user={user} />} />
              <Route path="/notification-management" element={<NotificationManagement user={user} />} />
            </Routes>
          </>
        )}
      </div>
    </div>
  );
}

export default App;