import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from './modules/axiosInstance';
import { toast } from 'react-toastify';
import './styles/Workspace.css';
import CreateTaskForm from './connectables/CreateTaskForm';
import Comment from './connectables/Comment';
import { fetchUsers, getUserNameById } from './modules/userService'
import RichTextEditor from './connectables/RichTextEditor';
import ProjectModal from './connectables/ProjectModal';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskTimer from './modules/taskTimer';
import wsMessage from './modules/wsMessage';
import AutocompleteInput from './connectables/AutocompleteInput';
import TasksList from './connectables/TasksList';
import SubtasksList from './connectables/SubtasksList';
import KanbanBoard from './KanbanBoard';
import SearchFilter from './modules/SearchFilter';
import UserProfile from './modules/UserProfile';
import CreateProjectModal from './connectables/CreateProjectModal';

function Workspace({ user, ws }) {
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]); // Храним список проектов
    const [isProjectOpen, setIsProjectOpen] = useState(false);
    const projectRef = useRef(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null); // Храним ID выбранного проекта
    const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'ascending' });
    const [subtaskSortConfig, setSubtaskSortConfig] = useState({ key: 'title', direction: 'ascending' });
    const [showTaskPanel, setShowTaskPanel] = useState(false);
    const [showSubtaskPanel, setShowSubtaskPanel] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [editingSubtask, setEditingSubtask] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(null);
    const [parentTask, setParentTask] = useState(null);
    const [timers, setTimers] = useState({}); // Храним таймеры для всех задач
    const [pinnedComments, setPinnedComments] = useState([]); // Храним закрепленный текст коммента
    const [isTempList, setIsTemplist] = useState();
    const [viewMode, setViewMode] = useState('list');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermModal, setSearchTermModal] = useState('');
    const [selectedAssigners, setSelectedAssigners] = useState([]);
    const [selectedExecutors, setSelectedExecutors] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false); // для открытия/закрытия модалки
    const [currentProject, setCurrentProject] = useState(null); // текущий проект для отображения в модалке
    const uniqueTags = [...new Set(tasks.map(task => task.tag))];
    const [searchTermMain, setSearchTermMain] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false)

    // Функция для получения данных из SearchFilter.js
    const handleFiltersChange = ({ searchTerm, searchTermModal, selectedAssigners, selectedExecutors, selectedStatuses, selectedTags }) => {
        setSearchTerm(searchTerm);
        setSearchTermModal(searchTermModal);
        setSelectedAssigners(selectedAssigners);
        setSelectedExecutors(selectedExecutors);
        setSelectedStatuses(selectedStatuses);
        setSelectedTags(selectedTags);
    };

    const templateList = [
        {
            key: '2025 Задача на графику',
            name: 'Имя для графики',
            description: 'Описание графики',
            tag: 'Тэг графики'
        },
        {
            key: '2025 Задача на видео',
            name: 'Имя для видео',
            description: 'Описание видео',
            tag: 'Тэг видео',
        },
        {
            key: '2025 Задача на звук',
            name: 'Имя для звука',
            description: 'Описание звука',
            tag: 'Тэг звука'
        },
        {
            key: '2025 Задача на фрагмент',
            name: 'Имя для фрагмента',
            description: 'Описание фрагмента',
            tag: 'Тэг фрагмента'
        },
    ];

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const activeTask = editingTask || editingSubtask;
    let currentUserId = user.id;
    const statusMapping = {
        new: "Новая задача",
        in_progress: "В работе",
        paused: "На паузе",
        deferred: "Отложена",
        closed: "Завершена",
        await_control: "Ждет контроля"
    }
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        assigner_id: null,
        executor_id: null,
        observers: [],
        tag: '',
        parent_task_id: null,
        deadline: null,
        project_id: null,
        project_name: null,
    });

    const openTaskById = async (taskId) => {
        try {
            const response = await axios.get(`/api/tasks/${taskId}`);
            const taskData = response.data;
            setEditingTask(taskData);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Ошибка при открытии задачи:', error);
        }
    };

    useEffect(() => {
        // Проверка URL для обработки ссылки на задачу
        const taskIdMatch = location.pathname.match(/\/tasks\/(\d+)/);
        if (taskIdMatch) {
            openTaskById(taskIdMatch[1]);
        }
    }, [location]);

    const fetchProjects = async () => {
        try {
            const response = await axios.get('/projects');
            const fetchedProjects = response.data;

            // сортировка проектов автоматически по времени идет, сделал по id
            const sortedProjects = fetchedProjects.sort((a, b) => a.id - b.id);

            setProjects(sortedProjects);

            if (sortedProjects.length > 0) {
                // Устанавливаем первый проект как предвыбранный
                setSelectedProjectId(sortedProjects[0].id);
            }
        } catch (error) {
            console.error('Ошибка при получении проектов:', error);
        }
    };

    useEffect(() => {
        fetchProjects(); // Загружаем проекты
        fetchUsers(setUsers); // Загружаем пользователей
    }, []);

    const getProjectById = (projectId, projects) => {
        return projects.find(project => project.id === projectId);
    };

    const fetchTasks = React.useCallback(async () => {
        try {
            const url = selectedProjectId
                ? `/projects/${selectedProjectId}/tasks` // Загружаем задачи выбранного проекта
                : 'tasks';
            const response = await axios.get(url);

            setTasks(response.data);

            // // Извлекаем подзадачи рекурсивно
            // const extractSubtasks = (tasks) => {
            //     let subtasksList = [];
            //     tasks.forEach((task) => {
            //         if (task.subtasks && task.subtasks.length > 0) {
            //             subtasksList = subtasksList.concat(task.subtasks);
            //             subtasksList = subtasksList.concat(extractSubtasks(task.subtasks)); // Рекурсия
            //         }
            //     });
            //     return subtasksList;
            // };

        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Нет доступа к задачам проекта');
                toast.error('Вы не являетесь участником проекта');
            } else {
                console.error('Ошибка при получении задач:', error);
            }
        }
    }, [selectedProjectId]);

    useEffect(() => {
        if (selectedProjectId !== null) {
            fetchTasks(); // Загружаем задачи только если проект выбран
            wsMessage(ws, fetchTasks);
        }
    }, [selectedProjectId, fetchTasks, ws, user.id]);

    const handleClickOutside = useCallback(e => {
        const modal = document.querySelector('.task-template');
        const btn = document.querySelector('.template-list');

        if (btn && btn.contains(e.target)) {
            return;
        }

        if (modal && !modal.contains(e.target)) {
            setIsTemplist(false);
        }
    }, []);

    useEffect(() => {
        if (isTempList) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isTempList, handleClickOutside]);

    const extractNumberFromTitle = (title) => {

        const eomPattern = /(\d{1,2}(?:\.\d{1,2})?[-.]\d{1,2}[-.]\d{5}-m\d\.\d+m?[-.]\d{2}[-.]\d{2})/g;
        const cokPattern = /(\d{1,2}(?:\.\d{1,2})?[-.]\d{1,2}[-.]\d{5})/g;

        const eomMatches = title.match(eomPattern);
        if (eomMatches) {
            return eomMatches[0];
        }

        const cokMatches = title.match(cokPattern);
        if (cokMatches) {
            return cokMatches[0];
        }

        console.log("Номер не найден ни по одному шаблону.");
        return null;
    };

    const searchCokByNumber = async (cokNumber) => {
        if (!cokNumber) return;

        setLoading(true);
        setIsSearch(true);
        setError(null);

        try {
            const response = await axios.get('/search-cok', { params: { cok: cokNumber } });

            if (response.data && response.data.cok) {
                const result = `${response.data.cok} — ${response.data.status || "Новый"}`;
                setSearchResult(result);
            } else {
                setSearchResult(null);
            }
        } catch (err) {
            setError('Ошибка при выполнении поиска ЦОК');
            setSearchResult(null);
        } finally {
            setLoading(false);
            setIsSearch(false);
        }
    };

    const searchEomByNumber = async (eomNumber, userId = 1) => {
        if (!eomNumber) return;

        setLoading(true);
        setIsSearch(true);
        setError(null);

        try {
            const response = await axios.get('/search-eom', { params: { eom: eomNumber, userId } });

            if (response.data && response.data.eom) {
                const result = `${response.data.eom} — ${response.data.status || "Новый"}`;
                setSearchResult(result);
            } else {
                setSearchResult(null);
            }
        } catch (err) {
            setError('Ошибка при выполнении поиска ЭОМ');
            setSearchResult(null);
        } finally {
            setLoading(false);
            setIsSearch(false);
        }
    };

    const openModal = async (task, isSubtask = false) => {
        setEditingTask(isSubtask ? null : task);
        setEditingSubtask(isSubtask ? task : null);
        setParentTask(null);
        setSearchResult(null);
        setLoading(true);
        setError(null);
        setShowSubtaskPanel(false);
        setIsDropdownOpen(false);

        if (!task) {
            toast.error('Задача не найдена');
            return;
        }

        const number = extractNumberFromTitle(task.title);
        const isMainTask = !isSubtask;

        try {
            const response = await axios.get(`/ui/tasks/${task.id}`);
            const taskData = response.data;

            const timerResponse = await axios.get(`/tasks/${task.id}/timer`);
            const timerValue = timerResponse.data.total_seconds || 0;


            setTimers((prevTimers) => ({
                ...prevTimers,
                [task.id]: timerValue,
            }));

            if (isMainTask) {
                // Если это основная задача, загружаем подзадачи
                if (taskData.subtasks && taskData.subtasks.length > 0) {
                    setEditingTask({ ...task, subtasks: taskData.subtasks });
                }

                if (number) {
                    searchCokByNumber(number); // Поиск ЦОК
                }
            } else {
                // Если это подзадача, загружаем родительскую задачу
                if (task.parent_task_id) {
                    try {
                        const parentResponse = await axios.get(`/ui/tasks/${task.parent_task_id}`);
                        setParentTask(parentResponse.data);
                    } catch (error) {
                        console.error('Ошибка при загрузке основной задачи:', error);
                    }
                }

                if (taskData.subtasks && taskData.subtasks.length > 0) {
                    setEditingSubtask({ ...task, subtasks: taskData.subtasks });
                }

                if (number) {
                    searchEomByNumber(number); // Поиск ЭОМ
                } else {
                    setSearchResult("номер не найден");
                    setLoading(false);
                }
            }

            setIsModalOpen(true);
        } catch (error) {
            console.error('Ошибка при загрузке данных задачи:', error);
            setError('Ошибка при загрузке данных задачи');
        } finally {
            setLoading(false);
        }
    };


    const closeModal = () => {
        setEditingTask(null);
        setEditingSubtask(null);
        setIsModalOpen(false);
        setIsEditing(false);
        setSearchResult(null);
        setLoading(false);
        setError(null);
        setIsDropdownOpen(false);
        setShowSubtaskPanel(false)
        setIsProjectOpen(false);

        if (location.pathname !== '/') {
            navigate('/tasks/');
        }
    };

    const handleTaskUpdate = async () => {
        try {
            const taskToUpdate = activeTask;
            const url = `/tasks/${taskToUpdate.id}`;
            await axios.put(url, taskToUpdate);
            fetchTasks();
            // wsMessage(ws, fetchTasks);
            setIsEditing(false);
        } catch (error) {
            console.error('Ошибка при обновлении задачи:', error);
        }
    };

    const addTask = async () => {
        try {
            await axios.post('/tasks', {
                ...newTask,
                observers: JSON.stringify(newTask.observers),
            });
            setNewTask({
                title: '',
                description: '',
                assigner_id: null,
                executor_id: null,
                observers: [],
                tag: '',
                parent_task_id: null,
                project_id: null,
                important: 0,
            });
            fetchTasks();
            setShowTaskPanel(false);
        } catch (error) {
            console.error('Ошибка при добавлении задачи:', error);
        }
    };

    // const toggleSubtasks = (task) => {
    //     if (selectedTaskId === task.id) {
    //         setSelectedTaskId(null);
    //         setSubtasks([]);
    //     } else {
    //         // Если задача не выбрана, загружаем подзадачи из `task.subtasks`
    //         if (task.subtasks && task.subtasks.length > 0) {
    //             setSubtasks(task.subtasks);
    //         } else {
    //             setSubtasks([]); // Если подзадач нет
    //         }
    //         setSelectedTaskId(task.id);
    //     }
    // };

    const sortTasks = (key) => {
        // Определяем направление сортировки
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';

        // Обновляем состояние сортировки
        setSortConfig({ key, direction });

        // Выполняем сортировку сразу
        const sorted = [...tasks].sort((a, b) => {
            if (direction === 'ascending') {
                return a[key] > b[key] ? 1 : -1;
            } else {
                return a[key] < b[key] ? 1 : -1;
            }
        });

        // Обновляем отображаемые задачи
        setTasks(sorted);
    };

    const sortSubtasks = (key) => {
        let direction = 'ascending';
        if (subtaskSortConfig.key === key && subtaskSortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSubtaskSortConfig({ key, direction });

        if (activeTask?.subtasks) {
            const sortedSubtasks = [...activeTask.subtasks].sort((a, b) => {
                if (a[key] < b[key]) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            setEditingTask({ ...editingTask, subtasks: sortedSubtasks });
        }
    };

    const sortedTasks = [...tasks].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    // получение подзадач для конкретной задачи, для перерисовки в таблице модалки
    const fetchSubtasks = async (parentTaskId) => {
        const response = await axios.get(`/tasks/${parentTaskId}`);
        const mainTask = response.data;
        setEditingTask(mainTask);
    }

    const handleParentTaskClick = async (parentTaskId) => {
        if (!parentTaskId) return;

        try {
            setEditingTask(null);
            setEditingSubtask(null);
            setParentTask(null);
            setSearchResult('Загрузка...');
            setLoading(true);
            setError(null);
            setShowSubtaskPanel(false);
            setIsDropdownOpen(false);

            const response = await axios.get(`/ui/tasks/${parentTaskId}`);
            const mainTask = response.data;

            // Проверяем, есть ли у текущей задачи родительская задача
            if (mainTask.parent_task_id) {
                const parentResponse = await axios.get(`/ui/tasks/${mainTask.parent_task_id}`);
                setParentTask(parentResponse.data);
                setEditingSubtask(mainTask);
            } else {
                // Если родительской задачи нет, то задача становится основной
                setEditingTask(mainTask);
                setParentTask(null);
            }

            setIsModalOpen(true);

            const number = extractNumberFromTitle(mainTask.title);
            if (number) {
                searchCokByNumber(number);
            } else {
                setSearchResult("номер не найден");
            }

            setLoading(false);
        } catch (error) {
            console.error('Ошибка при загрузке родительской задачи:', error);
            setLoading(false);
            setError('Ошибка при загрузке основной задачи');
        }
    };

    const notifications = new Set();

    const sendNotificationStatus = async (userId, task, status) => {
        try {
            await axios.post('/send-notification', {
                message: `Задача ${task?.title} ${status}`,
                userId: userId,
                senderId: currentUserId,
            });
        } catch (error) {
            console.error('Ошибка при отправке уведомления:', error);
            toast.error('Не удалось отправить уведомление');
        }
    }

    // Функция для обновления статуса задачи на сервере
    const updateTaskStatus = async (task, newStatus) => {
        try {
            const url = `/tasks/${task.id}/status`;
            await axios.put(url, { status: newStatus }); // Передаем только статус

            // Локальное обновление статуса задачи в состоянии
            setTasks((prevTasks) =>
                prevTasks.map((t) => (t.id === task.id ? { ...t, status: newStatus } : t))
            );

            // уведомление постановщику
            if (task?.assigner_id && task?.assigner_id !== currentUserId) {
                notifications.add(task.assigner_id);
            }

            // уведомление исполнителю
            if (task?.executor_id && task.executor_id !== currentUserId) {
                notifications.add(task.executor_id);
            }

            for (const userId of notifications) {
                await sendNotificationStatus(userId, task, statusMapping[newStatus].toLowerCase());
            }
        } catch (error) {
            console.error('Ошибка при обновлении статуса задачи:', error);
        }
    };


    // Функция для начала выполнения задачи
    const startTask = async (task) => {
        if (task) {
            try {
                task.status = 'in_progress';
                await updateTaskStatus(task, task.status); // Обновляем задачу на сервере
            } catch (error) {
                console.error('Ошибка при запуске задачи:', error);
            }
        }
    };

    const completeTask = (task) => {
        if (task) {
            // Если текущий пользователь — постановщик, сразу закрываем задачу
            if (task.assigner_id === currentUserId) {
                task.status = 'closed';
            } else {
                // Проверка на наличие хотя бы одного комментария
                if (pinnedComments.length === 0 || pinnedComments.every(comment => comment.comment.trim() === "")) {
                    alert("Пожалуйста, зафиксируйте хотя бы один результат перед завершением задачи.");
                    return; // Прерываем выполнение, если нет комментариев с результатом
                } else {
                    // Если текущий пользователь — исполнитель, задача ждет контроля
                    task.status = 'await_control';
                }
            }

            // Обновляем статус задачи
            updateTaskStatus(task, task.status);
        }
    };

    const approveTaskCompletion = (task) => {
        if (task) {
            task.status = 'closed'; // Задача окончательно закрывается
            updateTaskStatus(task, task.status);
        }
    };


    // Функция для приостановки выполнения задачи
    const pauseTask = (task) => {
        if (task) {
            task.status = 'paused';
            updateTaskStatus(task, task.status);
        }
    };

    // Функция для отложения задачи
    const deferTask = (task) => {
        if (task) {
            task.status = 'deferred';
            updateTaskStatus(task);
        }
    };

    // Функция для возврата задачи в работу
    const returnToInProgress = (task) => {
        if (task) {
            task.status = 'in_progress';
            updateTaskStatus(task, task.status);
        }
    };

    const deleteTask = async (taskId) => {
        try {
            if (taskId.parent_task_id) {
                await axios.delete(`/tasks/${taskId}`);
                setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
                fetchSubtasks();
            } else {
                await axios.delete(`/tasks/${taskId}`);
                setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
                fetchTasks();
            }

            setIsDropdownOpen(false);
            setIsModalOpen(false);

        } catch (error) {
            console.error('Ошибка при удалении задачи:', error);
        }
    };

    const toggleImportant = async (task) => {
        try {
            const updatedImportant = task.important ? 0 : 1;
            await axios.put(`/tasks/${task.id}/important`, { important: updatedImportant });

            setTasks((prevTasks) =>
                prevTasks.map((t) =>
                    t.id === task.id ? { ...t, important: updatedImportant } : t
                )
            );

            if (editingTask && editingTask.id === task.id) {
                setEditingTask({ ...editingTask, important: updatedImportant });
            }
            if (editingSubtask && editingSubtask.id === task.id) {
                setEditingSubtask({ ...editingSubtask, important: updatedImportant });
            }
        } catch (error) {
            console.error('Ошибка при обновлении важности задачи:', error);
        }
    };

    const formatTime = (seconds) => {
        const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    };

    const fetchTimer = async (taskId) => {
        try {
            const response = await axios.get(`/tasks/${taskId}/timer`);
            setTimers((prevTimers) => ({
                ...prevTimers,
                [taskId]: response.data.total_seconds,
            }));
        } catch (err) {
            console.error('Ошибка при получении таймера:', err);
        }
    };

    useEffect(() => {
        if (activeTask) {
            fetchTimer(activeTask.id);
        }
    }, [activeTask]);

    useEffect(() => {
        let intervalId;

        if (activeTask?.status === 'in_progress') {
            intervalId = setInterval(() => {
                setTimers((prevTimers) => ({
                    ...prevTimers,
                    [activeTask.id]: (prevTimers[activeTask.id] || 0) + 1,
                }));
            }, 1000);
        }

        return () => clearInterval(intervalId); // Очистка таймера при размонтировании
    }, [activeTask, activeTask?.status]);

    // форматируем время для предзаполненного инпута
    const prepareDatetime = (datetimeLocalValue) => {
        if (!datetimeLocalValue) return null; // Если значение пустое, возвращаем null

        const date = new Date(datetimeLocalValue);

        // Преобразуем в строку без изменения временной зоны
        const formattedDatetime = date
            .toLocaleString('sv-SE', { hour12: false })  // 'sv-SE' форматирует дату в формате 'YYYY-MM-DD HH:mm:ss'
            .replace(' ', 'T'); // Заменяем пробел на 'T' для соответствия формату базы данных

        return formattedDatetime;
    };

    const handleProjectClick = (projectId) => {
        console.log(`Клик по проекту с ID: ${projectId}`);
        setIsProjectOpen(!isProjectOpen);
        // Например, можно реализовать переход на страницу проекта
        // history.push(`/projects/${projectId}`);
    };

    const handleTemplate = (templateKey) => {
        setSelectedTemplate(templateKey);
        setShowSubtaskPanel(true);
    };

    // Обработчик для открытия модалки с информацией о проекте
    const openProjectModal = (project) => {
        setCurrentProject(project); // устанавливаем выбранный проект
        setIsProjectModalOpen(true); // открываем модалку
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTermMain(value);

        // Фильтрация задач и проектов по введенному запросу
        const results = filterSearchResults(value);
        setSearchResults(results);
    };

    const filterSearchResults = (query) => {
        if (!query) return [];

        const filteredProjects = projects.filter((project) =>
            project.name && project.name.toLowerCase().includes(query.toLowerCase())
        );

        const filteredTasks = tasks.filter((task) =>
            task.title && task.title.toLowerCase().includes(query.toLowerCase())
        );

        const filteredUsers = users.filter((user) =>
            user.name && user.name.toLowerCase().includes(query.toLowerCase())
        );

        return [
            ...filteredProjects.map(project => ({ type: 'project', ...project })),
            ...filteredTasks.map(task => ({ type: 'task', ...task })),
            ...filteredUsers.map(user => ({ type: 'user', ...user })) // Добавляем пользователей в результат
        ];
    };

    // Функция для открытия профиля пользователя
    const handleOpenProfile = (userId) => {
        const userProfile = users.find(user => user.id === userId);
        if (userProfile) {
            setSelectedUser(userProfile);
        }
    };

    const handleCloseProfile = () => {
        setSelectedUser(null);
    };

    const handleTagClick = (tag, projectId) => {
        handleFiltersChange({
            searchTerm: '',
            searchTermModal: '',
            selectedAssigners: [],
            selectedExecutors: [],
            selectedStatuses: [],
            selectedTags: [tag],
        });
        setSelectedProjectId(projectId);
        closeModal();
    };
    

    const handleCreateNewProject = async (projectData) => {
        try {
            const response = await axios.post('/projects', projectData);
            toast.success('Проект успешно создан');
            setProjects([...projects, { id: response.data.id, ...projectData }]);
            setIsCreateProjectModalOpen(false);
        } catch (error) {
            toast.error('Ошибка при создании проекта');
            console.error(error);
        }
    };

    
    return (
        <div className="workspace">
            <h2>Управление задачами</h2>
            <div className='add-task'>
                <button className="button" onClick={() => setShowTaskPanel(!showTaskPanel)}>
                    {showTaskPanel ? 'Скрыть панель задач' : 'Добавить задачу'}
                </button>
                {user.role === 'manager' && (
                    <button className="button" onClick={() => setIsCreateProjectModalOpen(true)}>
                        Создать проект
                    </button>
                )}

                {isCreateProjectModalOpen && (
                    <CreateProjectModal
                        onClose={() => setIsCreateProjectModalOpen(false)}
                        onCreate={handleCreateNewProject}
                        user={user}
                    />
                )}

            </div>

            {selectedProjectId !== null && (
                <>
                    <div className="view-mode-switch">
                        <button className="button" onClick={() => setViewMode(viewMode === 'list' ? 'kanban' : 'list')}>
                            {viewMode === 'list' ? 'Переключить в Kanban' : 'Вернуться к списку'}
                        </button>

                        <button className='button-out' onClick={() => setSelectedProjectId(null)}>Назад к проектам</button>
                    </div>

                    <div className="view-mode-info">
                        <div className="name-info">
                            <h2 style={{margin:"0"}}>
                                {projects.find(project => project.id === selectedProjectId)?.name || 'Проект не найден'}
                            </h2>
                            <button
                                className="info-button"
                                onClick={(e) => {
                                    const selectedProject = projects.find(project => project.id === selectedProjectId);
                                    openProjectModal(selectedProject);
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                                </svg>
                                <span className="tooltip-text">Информация о проекте</span>
                            </button>
                        </div>
                        <SearchFilter
                            users={users}
                            onFiltersChange={handleFiltersChange}
                            tags={uniqueTags}
                            selectedTags={selectedTags}
                            setSelectedTags={setSelectedTags}
                            searchTerm={searchTerm}
                            searchTermModal={searchTermModal}
                            selectedAssigners={selectedAssigners}
                            selectedExecutors={selectedExecutors}
                            selectedStatuses={selectedStatuses}
                        />
                    </div>
                </>
            )}

            {showTaskPanel &&
                <CreateTaskForm
                    newTask={newTask}
                    setNewTask={setNewTask}
                    projects={projects}
                    users={users}
                    tasks={tasks}
                    addTask={addTask}
                    setShowPan={setShowTaskPanel}
                // templateType={templateType}
                />
            }

            <div className="projects-container">
                {selectedProjectId === null ? (
                    <div>
                        <div className="search-container">
                            <input
                                type="text"
                                value={searchTermMain}
                                onChange={handleSearchChange}
                                placeholder="Поиск по проектам, задачам и пользователям"
                                className="search-input"
                            />
                            {searchTermMain && searchResults.length > 0 && (
                                <div className="search-results">
                                    {searchResults.map((result, index) => (
                                        <div key={index} className="search-result-item">
                                            {result.type === 'project' ? (
                                                <div onClick={() => setSelectedProjectId(result.id)}>
                                                    <h4>{result.name}</h4>
                                                    <span>Проект</span>
                                                </div>
                                            ) : result.type === 'task' ? (
                                                <div onClick={() => openModal(result)}>
                                                    <h4>{result.title}</h4>
                                                    <span>Задача</span>
                                                </div>

                                            ) : result.type === 'user' ? (
                                                <div onClick={() => handleOpenProfile(result.id)} >
                                                    <h4>{result.name}</h4>
                                                    <span>Пользователь</span>
                                                </div>
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {selectedUser && (
                                <>
                                    <div className="user-profile__overlay" onClick={handleCloseProfile}></div>
                                    <UserProfile
                                        user={selectedUser}
                                        currentUser={user}
                                        isOwnProfile={false}
                                        updateUser={(updatedUser) => {
                                            setSelectedUser(updatedUser);
                                        }}
                                        toggleProfile={handleCloseProfile}
                                    />
                                </>
                            )}
                        </div>

                        <div className="projects-list">
                            {projects.map(project => (
                                <div key={project.id} className="project-card" onClick={() => setSelectedProjectId(project.id)}>
                                    <h2>
                                        {project.name}
                                    </h2>
                                    <button
                                        className="info-button"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Останавливаем распространение клика
                                            openProjectModal(project); // Открываем модалку
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">
                                            <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                                        </svg>
                                        <span className="tooltip-text">Информация о проекте</span>
                                    </button>

                                </div>
                            ))}
                        </div>
                    </div>
                ) : (

                    viewMode === 'kanban' ? (
                        // Отображаем Канбан
                        <KanbanBoard
                            tasks={tasks.filter(task => task.project_id === selectedProjectId)}
                            updateTaskStatus={updateTaskStatus}
                            searchTerm={searchTerm}
                            selectedStatuses={selectedStatuses}
                            selectedAssigners={selectedAssigners}
                            selectedExecutors={selectedExecutors}
                            selectedTags={selectedTags}
                            getUserNameById={getUserNameById}
                            users={users}
                        />
                    ) : (
                        // Отображаем список задач
                        <TasksList
                            projects={projects}
                            selectedProjectId={selectedProjectId}
                            sortedTasks={sortedTasks}
                            openModal={openModal}
                            users={users}
                            getUserNameById={getUserNameById}
                            sortTasks={sortTasks}
                            sortConfig={sortConfig}
                            searchTerm={searchTerm}
                            selectedStatuses={selectedStatuses}
                            selectedAssigners={selectedAssigners}
                            selectedExecutors={selectedExecutors}
                            selectedTags={selectedTags}
                            searchTermModal={searchTermModal}
                        />
                    )
                )}
                {isProjectModalOpen && currentProject && (
                    <ProjectModal
                        project={currentProject}
                        setIsProjectOpen={setIsProjectModalOpen}
                        buttonRef={null}
                        currentUserId={user.id}
                    />
                )}
            </div>


            {/* Модальное окно задачи */}
            {isModalOpen && (activeTask) && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
                        <div className='pagetitle'>
                            <div className='pagetitle-menu'>
                                <p class="task-detail-subtitle-status ">Задача № {(activeTask)?.id || 'ID отсутствует'} - <span>{statusMapping[(activeTask)?.status]}</span></p>
                                <div className='pagetitle__wrapper'>
                                    <button className='menu-button' onClick={() => setShowSubtaskPanel(!showSubtaskPanel)}>
                                        {showSubtaskPanel ? 'Скрыть панель задач' : 'Добавить подзадачу'}
                                    </button>
                                    <button
                                        className={`task-template ${isTempList ? 'active' : ''}`}
                                        onClick={() => setIsTemplist(!isTempList)}
                                    ></button>
                                    {isTempList && (
                                        <ul className='template-list'>
                                            {templateList.map((template) => (
                                                <li
                                                    key={template.key}
                                                    className='template-item'
                                                    onClick={() => handleTemplate(template)}
                                                >
                                                    {template.key}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <span className="close-button" onClick={closeModal}>
                                &times;
                            </span>
                        </div>
                        {showSubtaskPanel &&
                            <div className='modal-subtask'>
                                <CreateTaskForm
                                    newTask={newTask}
                                    projects={projects}
                                    setNewTask={setNewTask}
                                    users={users}
                                    tasks={tasks}
                                    addTask={addTask}
                                    activeTask={activeTask}
                                    setShowPan={setShowSubtaskPanel}
                                    user={user}
                                    fetchSubtasks={fetchSubtasks}
                                    template={selectedTemplate}
                                    setTemplate={setSelectedTemplate}
                                />
                            </div>
                        }
                        <div className='modal-wrapper__bottom'>
                            <div className='description'>
                                <div className='description-comment'>
                                    <div className='task-detail-header'>
                                        <div className="task-detail-header-menu">
                                            <div className="title-edit-container">
                                                {isEditing ? (
                                                    <input
                                                        type="text"
                                                        value={activeTask?.title || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = e.target.value;
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, title: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, title: updatedValue });
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <h3 style={{ margin: '0' }}>
                                                        {(activeTask)?.title}
                                                    </h3>
                                                )}
                                            </div>
                                            <div
                                                id="task-detail-important-button"
                                                className="task-info-panel-important mutable"
                                                onClick={() => toggleImportant(activeTask)}
                                            >
                                                {activeTask?.important ? 'Это важная задача 🔥' : 'Сделать важной задачей'}
                                            </div>
                                        </div>
                                        <div className='copy-link-task'>
                                            <span
                                                className="copy-link-emoji"
                                                onClick={() => {
                                                    const link = `${window.location.origin}/tasks/${activeTask?.id}`;
                                                    navigator.clipboard.writeText(link).then(() => {
                                                        toast.success('Ссылка успешно скопирована!');
                                                    });
                                                }}
                                                title="Скопировать ссылку на задачу"
                                            >
                                                🔗
                                            </span>
                                        </div>
                                    </div>

                                    <div className='description-section'>
                                        {isEditing ? (
                                            <RichTextEditor
                                                newContent={(activeTask)?.description || ''}
                                                setNewContent={(content) => {
                                                    if (editingTask) {
                                                        setEditingTask({ ...editingTask, description: content });
                                                    } else if (editingSubtask) {
                                                        setEditingSubtask({ ...editingSubtask, description: content });
                                                    }
                                                }}
                                            />
                                        ) : (
                                            activeTask?.description ? (
                                                <p dangerouslySetInnerHTML={{ __html: activeTask.description }} />
                                            ) : (
                                                <p>Описание отсутствует</p>
                                            )
                                        )}
                                    </div>

                                    <div className='check-list-wrapper'>
                                        <p>+ добавить чек-лист</p>
                                    </div>

                                    <div className='task-detail-extra'>
                                        <div className='task-detail-group'>
                                            <p>Задача в проекте:</p>
                                            {!isEditing ? (
                                                <button
                                                    className='task-detail-btn'
                                                    ref={projectRef}
                                                    onClick={() => handleProjectClick(activeTask?.project_id)}
                                                >
                                                    {projects?.find(project => project.id === activeTask?.project_id)?.name || 'Проект не найден'}
                                                </button>
                                            ) : (
                                                <AutocompleteInput
                                                    options={projects}
                                                    selectedItems={activeTask?.project_id ? [activeTask.project_id] : []}
                                                    onChange={(ids) => {
                                                        const selectedProjectId = ids.length > 0 ? ids[0] : null;
                                                        if (editingTask) {
                                                            setEditingTask({
                                                                ...editingTask,
                                                                project_id: selectedProjectId
                                                            });
                                                        } else if (editingSubtask) {
                                                            setEditingSubtask({
                                                                ...editingSubtask,
                                                                project_id: selectedProjectId
                                                            });
                                                        }
                                                    }}
                                                    label="Проект"
                                                    displayField="name"
                                                    isMulti={false}
                                                />
                                            )}
                                        </div>

                                        {isProjectOpen &&
                                            <ProjectModal
                                                buttonRef={projectRef}
                                                project={getProjectById(activeTask?.project_id, projects)}
                                                setIsProjectOpen={setIsProjectOpen}
                                                currentUserId={user.id}
                                            />}

                                        {parentTask && editingSubtask && (
                                            <div className='task-detail-maintask'>
                                                <p>Основная задача:</p>
                                                {!isEditing ? (
                                                    <button
                                                        className='task-detail-btn'
                                                        onClick={() => handleParentTaskClick(parentTask.id)}
                                                    >
                                                        {parentTask.title}
                                                    </button>
                                                ) : (
                                                    <AutocompleteInput
                                                        options={tasks.filter(task => task.id !== editingSubtask.id)} // Исключаем текущую задачу и её подзадачи
                                                        selectedItems={parentTask.id ? [parentTask.id] : []}
                                                        onChange={(ids) => {
                                                            const selectedParentTaskId = ids.length > 0 ? ids[0] : null;
                                                            setEditingSubtask({
                                                                ...editingSubtask,
                                                                parent_task_id: selectedParentTaskId
                                                            });
                                                        }}
                                                        label="Основную задачу"
                                                        displayField="title"
                                                        isMulti={false}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {/* <div className='details-timer'>
                                        <TaskTimer activeTask={activeTask} timers={timers} />
                                    </div> */}

                                    <div className='task-view-buttonset'>
                                        <div className='details-timer'>
                                            <TaskTimer activeTask={activeTask} timers={timers} />
                                        </div>
                                        {(activeTask)?.status === 'new' && !isEditing && (
                                            <button className="button" onClick={() => startTask(activeTask, (activeTask)?.status)}>Начать выполнение</button>
                                        )}
                                        {(activeTask)?.status === 'in_progress' && !isEditing && (
                                            <button className="button" onClick={() => pauseTask(activeTask, (activeTask)?.status)}>Пауза</button>
                                        )}
                                        {(activeTask)?.status === 'paused' && !isEditing && (
                                            <button className="button" onClick={() => startTask(activeTask, (activeTask)?.status)}>Продолжить</button>
                                        )}
                                        {(activeTask)?.status === 'deferred' && !isEditing && (
                                            <button className="button" onClick={() => returnToInProgress(activeTask, (activeTask)?.status)}>Вернуть в работу</button>
                                        )}
                                        {(activeTask)?.status !== 'closed' && (activeTask)?.status !== 'deferred' && (activeTask)?.status !== 'await_control' && !isEditing && (
                                            <button className="button" onClick={() => completeTask(activeTask, (activeTask)?.status)}>Завершить</button>
                                        )}
                                        {(activeTask)?.status === 'await_control' && activeTask.assigner_id === currentUserId && !isEditing && (
                                            <>
                                                <button className="button" onClick={() => approveTaskCompletion(activeTask)}>Принять результат</button>
                                                <button className="button" onClick={() => pauseTask(activeTask)}>Отправить на доработку</button>
                                            </>
                                        )}


                                        <div className='dropdown-button'>
                                            {!isEditing && (
                                                <button className='dropdown-more' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>Еще</button>
                                            )}
                                            {isDropdownOpen && (
                                                <div className='dropdown-menu'>
                                                    {((activeTask)?.status === 'closed' || (activeTask)?.status === 'await_control') && (
                                                        <button onClick={() => returnToInProgress(activeTask)}>Вернуть в работу</button>
                                                    )}
                                                    {(activeTask)?.status !== 'deferred' && ((activeTask)?.status !== 'closed' || (activeTask)?.status !== 'await_control') && (
                                                        <button onClick={() => deferTask(activeTask)}>Отложить</button>
                                                    )}
                                                    {editingSubtask ? (
                                                        <button onClick={() => {
                                                            deleteTask(activeTask?.id);
                                                            handleParentTaskClick(parentTask.id);
                                                        }}
                                                        >Удалить</button>
                                                    ) : (
                                                        <button onClick={() => deleteTask(activeTask?.id)}>Удалить</button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {!isEditing ? (
                                            <button className='btn-overlay-edit' onClick={() => setIsEditing(true)}>Редактировать</button>
                                        ) : (
                                            <>
                                                <button className='btn-overlay-save' onClick={handleTaskUpdate}>Сохранить изменения</button>
                                                <button className='btn-overlay-cancel' onClick={() => setIsEditing(false)}>Выйти из редактирования</button>
                                            </>
                                        )}
                                    </div>

                                </div>

                                {!isEditing && activeTask && activeTask.subtasks?.length > 0 && (
                                    <SubtasksList
                                        activeTask={activeTask}
                                        openModal={openModal}
                                        users={users}
                                        getUserNameById={getUserNameById}
                                        sortSubtasks={sortSubtasks}
                                        subtaskSortConfig={subtaskSortConfig}
                                    />
                                )}

                                {!isEditing && pinnedComments.length > 0 && activeTask?.id === pinnedComments[0].task_id && (
                                    <motion.div
                                        className='result-section'
                                        initial={{ opacity: 0, y: -10 }} // Исходное состояние: прозрачность 0, немного выше
                                        animate={{ opacity: 1, y: 0 }}  // Анимация: прозрачность 1, положение по оси Y нормализовано
                                        exit={{ opacity: 0, y: -10 }}   // Анимация исчезновения
                                        transition={{ duration: 0.5 }}  // Плавная анимация за 0.3 секунды
                                    >
                                        <div className='result-wrapper'>
                                            <p style={{ margin: '0', paddingLeft: '10px', paddingBottom: '10px', textAlign: 'left', borderBottom: '1px solid #eaebedc7', }}>Результат</p>

                                            {pinnedComments.length > 0 && (
                                                <div className='comment-result'>
                                                <div className='comment-header'>
                                                    <span className={
                                                    pinnedComments[pinnedComments.length - 1].user_id === currentUserId
                                                        ? 'user-name user-current'
                                                        : 'user-name user-other'
                                                    }>
                                                    {pinnedComments[pinnedComments.length - 1].user_name}
                                                    </span>
                                                    <span className='timestamp timestamp-left'>
                                                    {new Date(pinnedComments[pinnedComments.length - 1].timestamp).toLocaleString('ru-RU', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })}
                                                    </span>
                                                </div>
                                                <p
                                                    style={{ margin: 0, marginBottom: '20px' }}
                                                    dangerouslySetInnerHTML={{ __html: pinnedComments[pinnedComments.length - 1].comment }}
                                                />
                                            </div>
                                        )}

                                        {showAll && pinnedComments.slice(0, -1).map((comment, index) => (
                                            <div key={index} className='comment-result'>
                                            <div className='comment-header'>
                                                <span className={
                                                comment.user_id === currentUserId
                                                    ? 'user-name user-current'
                                                    : 'user-name user-other'
                                                }>
                                                {comment.user_name}
                                                </span>
                                                <span className='timestamp timestamp-left'>
                                                {new Date(comment.timestamp).toLocaleString('ru-RU', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                                </span>
                                            </div>
                                            <p
                                                style={{ margin: 0, marginBottom: '20px' }}
                                                dangerouslySetInnerHTML={{ __html: comment.comment }}
                                            />
                                            </div>
                                        ))}
                                        {pinnedComments.length > 1 && (
                                            <button
                                            className="show-more-button"
                                            onClick={() => setShowAll(prev => !prev)}
                                            >
                                            {showAll ? 'Свернуть' : `Еще ${pinnedComments.length - 1}`}
                                            </button>
                                        )}
                                        </div>
                                    </motion.div>
                                    )}


                                {!isEditing && (
                                    <div className="comment-section">
                                        {activeTask ? (
                                            
                                            <Comment
                                                entity={activeTask}
                                                entityType="tasks"
                                                currentUser={currentUserId}
                                                users={users}
                                                setPinnedComments={setPinnedComments}
                                                projects={projects}
                                                ws={ws}
                                                user={user}
                                            />
                                        ) : (
                                            <p>Выберите задачу, чтобы просмотреть или добавить комментарии</p>
                                        )}
                                    </div>
                                )}

                            </div>

                            <div className='info-block'>
                                <div className='updated-task'>
                                    <p>{statusMapping[(activeTask)?.status]} <span className='time-to-status'>{new Date((activeTask)?.updated_at).toLocaleString('ru-RU', {
                                        day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
                                    })}</span></p>
                                </div>
                                <div className='info-block__bottom'>

                                    <div className='status-block'>
                                        <div className='status-search'>
                                            <p>{activeTask.parent_task_id ? 'Статус ЭОМ:' : 'Статус ЦОК:'}</p>
                                            {loading ? (
                                                <p>Загрузка...</p>
                                            ) : error ? (
                                                <p>{error}</p>
                                            ) : isSearch ? (
                                                <p>Загрузка...</p>
                                            ) : searchResult ? (
                                                <p>{searchResult}</p>
                                            ) : (
                                                <p>{activeTask.parent_task_id ? 'ЭОМ не найден' : 'ЦОК не найден'}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className='data-card'>
                                        <div className='timing'>
                                            <div className='created_task'>
                                                <p> Создана: <span>{new Date((activeTask)?.created_at).toLocaleString('ru-RU', {
                                                    day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
                                                })}</span></p>
                                            </div>
                                            <hr className='timing__hr' />
                                            <div className='deadline-task'>
                                                {!isEditing ? (
                                                    <p>Крайний срок: <span><b>{activeTask?.deadline == null ? 'Не указан' : new Date(activeTask?.deadline).toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</b></span></p>
                                                ) : (
                                                    <p>
                                                        Крайний срок:
                                                        <span>
                                                            <input
                                                                type='datetime-local'
                                                                value={prepareDatetime(activeTask?.deadline) || ''}
                                                                onChange={(e) => {
                                                                    const value = e.target.value || null;
                                                                    if (editingTask) {
                                                                        setEditingTask({ ...editingTask, deadline: value })
                                                                    } else if (editingSubtask) {
                                                                        setEditingSubtask({ ...editingTask, deadline: value })
                                                                    }
                                                                }}
                                                            />
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                            <hr className='timing__hr' />
                                            <div className='details-timer-save'>
                                                <p> Затрачено: <span>{formatTime(timers[activeTask.id] || 0)}</span> </p>
                                            </div>
                                            <hr className='timing__hr' />
                                        </div>
                                        <div className='members'>

                                            {/* Постановщик */}
                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'assigner-block'}>
                                                <label>Постановщик</label>
                                                {!isEditing ? (
                                                    <p>{getUserNameById((activeTask)?.assigner_id, users)}</p>
                                                ) : (
                                                    <AutocompleteInput
                                                        options={users}
                                                        selectedItems={(activeTask?.assigner_id) ? [activeTask.assigner_id] : []}
                                                        onChange={(ids) => {
                                                            const newValue = ids.length > 0 ? ids[0] : null;
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, assigner_id: newValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, assigner_id: newValue });
                                                            }
                                                        }}
                                                        label="Постановщик"
                                                        displayField="name"
                                                        isMulti={false}
                                                    />
                                                )}
                                            </div>

                                            {/* Исполнитель */}
                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'executor-block'}>
                                                <label>Исполнитель</label>
                                                {!isEditing ? (
                                                    <p>{getUserNameById((activeTask)?.executor_id, users)}</p>
                                                ) : (
                                                    <AutocompleteInput
                                                        options={users}
                                                        selectedItems={(activeTask?.executor_id) ? [activeTask.executor_id] : []}
                                                        onChange={(ids) => {
                                                            const newValue = ids.length > 0 ? ids[0] : null;
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, executor_id: newValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, executor_id: newValue });
                                                            }
                                                        }}
                                                        label="Исполнитель"
                                                        displayField="name"
                                                        isMulti={false}
                                                    />
                                                )}
                                            </div>

                                            {/* Наблюдатели */}
                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'observers-block'}>
                                                <label>Наблюдатели</label>
                                                {!isEditing ? (
                                                    Array.isArray(activeTask?.observers) && activeTask?.observers.length > 0 ? (
                                                        activeTask.observers
                                                            .map((id) => {
                                                                const user = users.find((user) => user.id === id);
                                                                return user ? <p key={id}>{user.name}</p> : null;
                                                            })
                                                    ) : (
                                                        <p>Наблюдатели не назначены</p>
                                                    )
                                                ) : (
                                                    <AutocompleteInput
                                                        options={users}
                                                        selectedItems={activeTask?.observers || []}
                                                        onChange={(ids) => {
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, observers: ids });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, observers: ids });
                                                            }
                                                        }}
                                                        label="Наблюдатели"
                                                        displayField="name"
                                                        isMulti={true}
                                                    />
                                                )}
                                            </div>

                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'tags-block'}>
                                                <label>Теги</label>
                                                {!isEditing ? (
                                                    <p   onClick={() => {
                                                        if (activeTask?.tag) {
                                                          handleTagClick(activeTask.tag, activeTask.project_id);
                                                        }
                                                      }}>{(activeTask)?.tag || 'Тег не указан'}</p>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={(activeTask)?.tag || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = e.target.value;
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, tag: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, tag: updatedValue });
                                                            }
                                                        }}
                                                        disabled={!isEditing}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default Workspace;
