import React, { useEffect, useState } from 'react';
import axios from './modules/axiosInstance';
import './styles/style.css';
import { toast } from 'react-toastify';
import ChangeLog from './ChangeLog';

function SearchPage({ user }) {
  const [tsokInput, setTsokInput] = useState('');
  const [tsokData, setTsokData] = useState();
  const [tsok, setTsok] = useState('');
  const [eoms, setEoms] = useState([]);
  const [selectedEom, setSelectedEom] = useState(null);
  const [tsokComment, setTsokComment] = useState('');
  const [newTsokComment, setNewTsokComment] = useState('');
  const [comment, setComment] = useState('');
  const [newComment, setNewComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingEom, setIsEditingEom] = useState(false);
  const [newEomName, setNewEomName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAddingEom, setIsAddingEom] = useState(false);
  const [isSavingEom, setIsSavingEom] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingComEom, setIsEditingComEom] = useState(false);
  const [taskId, setTaskId] = useState(null);
  // const [tsokTaskId, setTsokTaskId] = useState(null);

  useEffect(() => {
    if (tsokData?.tsok) {
      setNewEomName(tsokData.tsok + '-');
    }
  }, [tsokData]);

  const handleTsokChange = (e) => {
    const input = e.target.value;
    setTsokInput(input.trim());
    if (input) {
      const extractedTsok = input.split('-').slice(0, 3).join('-');
      setTsok(extractedTsok);
    } else {
      setTsok('');
    }
  };

  const fetchEoms = async () => {
    if (!tsok.trim()) {
      toast.error('Номер ЦОКа не введен');
      return;
    }

    const role = user.user_group;

    setTsokData(null);
    setEoms([]);
    setSelectedEom(null);
    setComment('');
    setNewComment('');

    try {
      const response = await axios.get(`/search?tsok=${encodeURIComponent(tsok)}&role=${encodeURIComponent(role)}`);

      if (response.data.length === 0) {
        toast.error('Номер ЦОКа не найден');
      } else {
        setTsokData({
          id: response.data.id,
          tsok: response.data.tsok,
          tsok_comment: response.data.tsok_comment,
          responsible: response.data.responsible
        });

        setEoms(response.data.eoms);
        setTsokComment(response.data.tsok_comment);
        // const tasksResponse = await axios.get(`/bitrix-tasks?eomName=${tsok}`);

      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Ошибка при получении ЭОМов');
      }
    }
  };

  const updateTsokComment = async () => {
    if (newTsokComment.trim() === tsokComment.trim()) {
      setIsEditing(false);
      setNewTsokComment('');
      return;
    }

    setIsUpdating(true);
    try {
      await axios.post('/update-tsok-comment', {
        tsok: tsokData.tsok,
        newTsokComment,
        userId: user.id,
        tsokId: tsokData.id,
        role: user.user_group
      });
      setTsokComment(newTsokComment);
      setIsEditing(false);
      setNewTsokComment('');
      setErrorMessage('');
    } catch (error) {
      toast.error('Ошибка при обновлении комментария к ЦОКу');
    } finally {
      setIsUpdating(false);
    }
  };


  const updateEomComment = async () => {
    const trimmedComment = newComment.trim();
    const oldComment = selectedEom.eom_comment?.trim() || '';

    if (trimmedComment === oldComment) {
      setIsEditingComEom(false);
      setNewComment('');
      return;
    }

    setIsUpdating(true);

    try {
      await axios.post('/update-comment', {
        tsok: tsokData.tsok,
        eom: selectedEom?.eom,
        newComment: trimmedComment === '' ? null : trimmedComment,
        userId: user.id,
        eomId: selectedEom.eom_id,
        role: user.user_group
      });
      setEoms((prevEoms) =>
        prevEoms.map(eom =>
          eom.eom === selectedEom.eom
            ? { ...eom, eom_comment: trimmedComment }
            : eom
        )
      );
      setComment(trimmedComment);
      setSelectedEom({ ...selectedEom, eom_comment: trimmedComment });
      setIsEditingComEom(false);
      setNewComment('');
    } catch (error) {
      toast.error('Ошибка при обновлении комментария к ЭОМу');
    } finally {
      setIsUpdating(false);
    }
  };

  const updateStatus = async (newStatus) => {
    setIsLoading(true);
    try {

      let updatedResponsible = { ...tsokData.responsible };

      if (user.user_group === 'Admin') {
        // Добавляем нового админа, но не более 3-х
        const admins = updatedResponsible.Admin ? [...updatedResponsible.Admin] : [];
        if (!admins.includes(user.name)) {
          if (admins.length >= 3) admins.shift(); // Убираем первого, если их больше 3-х
          admins.push(user.name);
        }
        updatedResponsible.Admin = admins;
      } else {
        // Для Methodist и Editor просто заменяем
        updatedResponsible[user.user_group] = user.name;
      }
      const filteredResponsible = updatedResponsible[user.user_group];

      await axios.post('/update-status', {
        tsok: tsokData.tsok,
        tsokId: tsokData.id,
        eom: selectedEom?.eom,
        newStatus,
        engNewStatus: statusMap[newStatus],
        userId: user.id,
        eomId: selectedEom?.eom_id,
        role: user.user_group,
        responsible: filteredResponsible
      });
      setTsokData({ ...tsokData, responsible: updatedResponsible })
      setEoms((prevEoms) =>
        prevEoms.map(eom =>
          eom.eom === selectedEom.eom
            ? { ...eom, eom_status: statusMap[newStatus], status_upd: new Date() }
            : eom
        )
      );
      setSelectedEom({ ...selectedEom, eom_status: statusMap[newStatus], status_upd: new Date() })
      setErrorMessage('');
    } catch (error) {
      toast.error('Ошибка при обновлении статуса');
    } finally {
      setIsLoading(false);
    }
  };

  const statusMap = {
    'Готов': 'ready',
    'На графике': 'graphic',
    'На видео': 'video',
    'На звуке': 'audio',
    'У методиста': 'methodist',
    'Черновик': 'draft',
    "Отредактировано": "edited",
    "Направлен автору": "sent to author",
    "Заведен в БПМ": "registered in bpm",
    "Сдан": "delivered",
    "Монтаж": "installation",
    // "У редактора": "with editor",
    "ТЗ подготовлено": "technical task prepared",
    "На монтаже": "in installation",
    "Перевычитка": "rereading",
    '': '',
  };

  const reverseStatusMap = Object.fromEntries(
    Object.entries(statusMap).map(([key, value]) => [value, key])
  );

  const getStatusClass = (status) => {
    switch (status) {
      case 'Готов':
        return 'status ready';
      case 'На графике':
        return 'status graphic';
      case 'На видео':
        return 'status video';
      case 'На звуке':
        return 'status audio';
      case 'Отредактировано':
        return 'status editor';
      case 'У методиста':
        return 'status methodist';
      case 'ТЗ подготовлено':
        return 'status tzready';
      case 'На монтаже':
        return 'status montazh';
      case 'Черновик':
        return 'status chernovyk';
        case 'Перевычитка':
          return 'status rereading'
      default:
        return 'status';
    }
  };

  const maxStatusLength = Math.max(...user.statuses.map(status => status.length), 0);
  const statusWrapperWidth = `${maxStatusLength * 9}px`;

  const renderStatusButtons = () => {
    const statuses = user.statuses || [];

    return (
      <>
        {statuses.map((newStatus, index) => (
          <button
            className="button"
            key={index}
            onClick={() => updateStatus(newStatus)}
            disabled={isLoading || reverseStatusMap[selectedEom.eom_status] === newStatus}
          >
            {newStatus}
          </button>
        ))}
        <button
          className="button"
          onClick={() => updateStatus('')}
          disabled={isLoading || reverseStatusMap[selectedEom.eom_status] === ''}
        >
          Очистить статус
        </button>
      </>
    );
  };

  const handleEomSelection = async (eomObj) => {
    setSelectedEom(eomObj);

    setTaskId(null);
    setComment('');

    try {
      const cleanEomName = eomObj?.eom.replace(' (остановлен)', '');
      const tasksResponse = await axios.get(`/bitrix-tasks?eomName=${cleanEomName}`);
      if (tasksResponse.data.length > 0) {
        setTaskId(tasksResponse.data[0].id);
      } else {
        setTaskId(null);
      }

      const eomData = eoms.find(eom => eom.eom === eomObj.eom);
      if (eomData) {
        setComment(eomData.eom_comment || '');
      }
    } catch (error) {
      setTaskId(null);
    }
  };

  const handleEomEdit = async () => {
    if (!newEomName) return;
    if (newEomName.trim() === selectedEom.eom.trim()) {
      setIsEditingEom(false);
      setNewEomName('');
      return;
    }

    setIsUpdating(true);
    try {
      await axios.post('/update-eom-name', {
        tsok: tsokData.tsok,
        oldEomName: selectedEom?.eom,
        newEomName,
        userId: user.id,
        eomId: selectedEom.eom_id
      });
      setEoms((prevEoms) =>
        prevEoms.map(eom =>
          eom.eom === selectedEom.eom
            ? { ...eom, eom: newEomName }
            : eom
        )
      );
      setSelectedEom({ ...selectedEom, eom: newEomName })
      setIsEditingEom(false);
      setErrorMessage('');
    } catch (error) {
      toast.error('Ошибка при обновлении названия ЭОМа');
    } finally {
      setIsUpdating(false);
    }
  };

  const createNewTsok = async (tsok) => {
    if (!tsok || !user.id) {
      console.error("Ошибка: отсутствуют обязательные параметры (tsok или userId)");
      return;
    }

    try {
      const response = await axios.post('/add-tsok', {
        tsok,
        userId: user.id
      });
      setTsokData({
        id: response.data.tsokId,
        tsok: tsok,
        tsok_comment: '',
        responsible: '',
      });

      return response.data;
    } catch (error) {
      console.error("Ошибка при создании нового ЦОКа:", error.response?.data || error.message);
    }
  };

  const handleAddEom = async () => {
    if (!newEomName) return;

    setIsSavingEom(true);

    try {
      const response = await axios.post('/add-eom', {
        tsok: tsokData.tsok,
        newEomName,
        userId: user.id,
        tsokId: tsokData.id,
        scenario: 0,
      });
      setEoms([...eoms, {
        eom_id: response.data.eomId,
        eom: newEomName,
        eom_status: '',
        eom_comment: '',
        hang: 0,
      }]);
      setIsAddingEom(false);
      setNewEomName(tsokData.tsok + '-');
    } catch (error) {
      toast.error('Ошибка при добавлении нового ЭОМа');
    } finally {
      setIsSavingEom(false);
    }
  };

  const handleAddScenario = async () => {
    const scenarioName = 'Сценарный план';

    try {
      const response = await axios.post('/add-eom', {
        tsok: tsokData.tsok,
        newEomName: scenarioName,
        userId: user.id,
        tsokId: tsokData.id,
        scenario: 1,
      });
      setEoms([...eoms, {
        eom_id: response.data.eomId,
        eom: scenarioName,
        eom_status: '',
        eom_comment: '',
        hang: 0,
        scenario: 1,
      }]);
    } catch (error) {
      toast.error('Ошибка при добавлении сценарного плана');
    }
  }

  const deleteEom = async (eomObj) => {
    if (!eomObj) {
      console.error('ЭОМ не найден.');
      return;
    }

    if (selectedEom === eomObj) {
      setSelectedEom(null);
    };

    try {
      await axios.post('/delete-eom', {
        tsok: tsokData.tsok,
        eomId: eomObj.eom_id,
        eom: eomObj.eom,
        userId: user.id,
      });

      setEoms(prevEoms => prevEoms.filter(eom => eom.eom_id !== eomObj.eom_id));
    } catch (error) {
      console.error('Ошибка при удалении ЭОМ:', error.response?.data || error.message);
    }
  }

  return (
    <div style={{ margin: '0 20px' }}>
      <div className='search-page'>
        <div className='search'>
          <h2>Поиск по номеру ЦОК или ЭОМ</h2>
          <input
            type="text"
            value={tsokInput}
            onChange={handleTsokChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchEoms();
              }
            }}
            placeholder="Введите номер ЦОК или ЭОМ"
          />
          <button className="button" onClick={fetchEoms}>Получить ЭОМы</button>
          {user.role === 'manager' && (
            <button className="button" onClick={() => createNewTsok(tsokInput.trim())}>Создать ЦОК</button>
          )}
        </div>
        {errorMessage && <p className="error">{errorMessage}</p>}

        {tsokData && (
          <div className='tsok-comment'>
            <div>
              <span>ЦОК: <b>{tsokData.tsok}</b></span>
            </div>
            <div className='link_bitrix_tsok'>
              {tsokData?.id ? (
                <button
                  onClick={() => window.open(`https://academymp.bitrix24.ru/company/personal/user/0/tasks/task/view/${tsokData?.id}/`, '_blank')}
                  className="bitrix-button"
                >
                  Перейти в задачу ЦОКа
                </button>
              ) : (
                <span>Задача ЦОКа не найдена</span>
              )}
            </div>
            <div>
              {!tsokComment ? (
                <div className='tsok-comment-edit' style={{ marginTop: '10px' }}>
                  <p style={{ margin: '0' }}>Комментарий к ЦОКу:</p>
                  <input
                    type="text"
                    className="tsok-comment-input"
                    value={newTsokComment}
                    onChange={(e) => setNewTsokComment(e.target.value)}
                    placeholder="Новый комментарий к ЦОК"
                  />
                  <button className='button' onClick={updateTsokComment} disabled={isUpdating}>
                    Обновить комментарий
                  </button>
                </div>
              ) : (
                <div>
                  <p>
                    Комментарий к ЦОКу: <b>{tsokComment}</b>
                    <span
                      className="edit-icon"
                      onClick={() => {
                        setIsEditing(true);
                        setNewTsokComment(tsokComment);
                      }}
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                    >
                      ✏️
                    </span>
                  </p>

                  {/* Если активен режим редактирования, отображаем поле и кнопки */}
                  {isEditing && (
                    <div className="tsok-comment-edit">
                      <input
                        type="text"
                        className="tsok-comment-input"
                        value={newTsokComment}
                        onChange={(e) => setNewTsokComment(e.target.value)}
                        autoFocus
                      />
                      <button className="button" onClick={updateTsokComment} disabled={isUpdating}>
                        Сохранить
                      </button>
                      <button className="button" onClick={() => setIsEditing(false)}>
                        Отмена
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {tsokData?.responsible && (
              <div className='proof-editor-data'>
                <div className='proof-editor-row'>
                  <p><b>Редактор:</b> {tsokData.responsible.Editor || 'Нет данных'}</p>
                  <p><b>Методист:</b> {tsokData.responsible.Methodist || 'Нет данных'}</p>
                  <p><b>Разработчики:</b> {Array.isArray(tsokData.responsible.Admin)
                    ? tsokData.responsible.Admin.join(', ')
                    : tsokData.responsible.Admin || 'Нет данных'}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}

        <ul className="eom-list">
          {eoms.length > 0 &&
            eoms
              .filter((eom) => eom.scenario !== 1 || (user.user_group === 'Methodist' || user.user_group === 'Editor'))
              .map((eom, index) => {
                return (
                  <li className='eom-list__item' key={index} id={eom.eom_id}>
                    <button
                      className={`eom-list__button ${selectedEom?.eom === eom?.eom ? 'selected' : ''}`}
                      onClick={() => handleEomSelection(eom)}
                    >
                      <p className='eom-title'>{eom?.eom}</p>
                      <div className="status-wrapper" style={{ width: statusWrapperWidth }}>
                        <span className={getStatusClass(reverseStatusMap[eom?.eom_status])}>{reverseStatusMap[eom?.eom_status]}</span>
                      </div>
                    </button>
                    {user.role === 'manager' && (
                      <button className='eom-button eom__button-delete' onClick={() => deleteEom(eom)}>Удалить ЭОМ</button>
                    )}
                  </li>
                );
              })}
          {tsokData && (
            <>
              <button className="button" onClick={() => setIsAddingEom(true)} disabled={isSavingEom}>
                Добавить новый ЭОМ
              </button>
              {(user.user_group === 'Methodist' || user.user_group === 'Editor') && (
                <button className='button' onClick={handleAddScenario}>Добавить сценарный план</button>
              )}
            </>
          )}
          {isAddingEom && (
            <div className="add-eom-modal">
              <input
                type="text"
                value={newEomName}
                onChange={(e) => setNewEomName(e.target.value)}
              />
              <button className="button" onClick={handleAddEom} disabled={isSavingEom}>
                Сохранить
              </button>
              <button className="button" onClick={() => { setNewEomName(tsokData?.tsok + '-'); setIsAddingEom(false) }} disabled={isSavingEom}>
                Отмена
              </button>
            </div>
          )}
        </ul>

        {selectedEom && (
          <div className='selected-eom-container'>
            <div className='eom-header'>
              <b>Выбранный ЭОМ: </b>
              {isEditingEom ? (
                <>
                  <input
                    type="text"
                    value={newEomName}
                    onChange={(e) => setNewEomName(e.target.value)}
                  />
                  <button className="button" onClick={handleEomEdit} disabled={isUpdating}>Сохранить</button>
                  <button className="button" onClick={() => setIsEditingEom(false)} disabled={isUpdating}>Отмена</button>
                </>
              ) : (
                <b><u>{selectedEom?.eom}</u></b>
              )}
              <span
                className="edit-icon"
                onClick={() => {
                  setIsEditingEom(true);
                  setNewEomName(selectedEom?.eom);
                }}
              >✏️</span>
            </div>
            <div className='link_bitrix'>
              {taskId ? (
                <button
                  onClick={() => window.open(`https://academymp.bitrix24.ru/company/personal/user/0/tasks/task/view/${taskId}/`, '_blank')}
                  className="bitrix-button"
                >
                  Перейти в задачу
                </button>
              ) : (
                <span>Задача не найдена</span>
              )}
            </div>
            <p>Статус: <span className={getStatusClass(reverseStatusMap[selectedEom?.eom_status])}>{reverseStatusMap[selectedEom?.eom_status]}</span></p>
            <div className='status-button'>
              {renderStatusButtons()}
            </div>
            <div>
              <p>Дата изменения: <b>{selectedEom?.status_upd ? new Date(selectedEom?.status_upd).toLocaleDateString() : ''}</b></p>
            </div>

            <div>
              {!comment ? (
                <div>
                  <p className='comment-add'>
                    Комментарий к ЭОМ:
                    <input
                      type="text"
                      className="eom-comment-input"
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Новый комментарий к ЭОМ"
                    />
                    <button className="button" onClick={updateEomComment} disabled={isUpdating}>
                      Обновить комментарий
                    </button>
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    Комментарий к ЭОМ: <b>{comment}</b>
                    <span
                      className="edit-icon"
                      onClick={() => {
                        setIsEditingComEom(true);
                        setNewComment(comment);
                      }}
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                    >
                      ✏️
                    </span>
                  </p>

                  {isEditingComEom && (
                    <div>
                      <input
                        type="text"
                        className="eom-comment-input"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        autoFocus
                      />
                      <button className="button" onClick={updateEomComment} disabled={isUpdating}>
                        Сохранить
                      </button>
                      <button className="button" onClick={() => setIsEditingComEom(false)}>
                        Отмена
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>


          </div>
        )}
      </div>
      {user.role === 'manager' && (
        <ChangeLog />
      )}
    </div>
  );
}

export default SearchPage;
