import { useEffect, useRef, useState } from 'react';
import axiosInstance from '../modules/axiosInstance';
import { toast } from 'react-toastify';
import AutocompleteInput from "./AutocompleteInput"
import '../styles/Project.css';

function ProjectModal({ project, setIsProjectOpen, buttonRef, currentUserId }) {
    const modalRef = useRef(null);
    const [projectUsers, setProjectUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [newUserRole, setNewUserRole] = useState({ userId: null, role: 'participant' });

    // Определяем роль текущего пользователя
    const currentUserRole = projectUsers.find((user) => user.id === currentUserId)?.role || 'participant';

    useEffect(() => {
        function closeProject(e) {
            if (
                modalRef.current &&
                !modalRef.current.contains(e.target) &&
                !(buttonRef.current && buttonRef.current.contains(e.target))
            ) {
                setIsProjectOpen(false);
            }
        }
        document.addEventListener('mousedown', closeProject);
        return () => {
            document.removeEventListener('mousedown', closeProject);
        };
    }, [setIsProjectOpen, buttonRef]);

    // Загрузка участников проекта
    useEffect(() => {
        const fetchProjectUsers = async () => {
            try {
                const response = await axiosInstance.get(`/projects/${project.id}/users`);
                setProjectUsers(response.data || []);
            } catch (err) {
                console.error('Ошибка при загрузке участников проекта:', err);
            }
        };

        const fetchAllUsers = async () => {
            try {
                const response = await axiosInstance.get('/users');
                setAllUsers(response.data || []);
            } catch (err) {
                console.error('Ошибка при загрузке всех пользователей:', err);
            }
        };

        fetchProjectUsers();
        fetchAllUsers();
    }, [project.id]);

    const addUsersToProject = async () => {
        if (!newUserRole.observers || newUserRole.observers.length === 0) {
            alert('Выберите хотя бы одного пользователя');
            return;
        }

        try {
            await Promise.all(
                newUserRole.observers.map((userId) =>
                    axiosInstance.post(`/projects/${project.id}/users`, {
                        userId,
                        role: newUserRole.role || 'participant',
                    })
                )
            );

            setNewUserRole({ userId: null, observers: [], role: 'participant' });

            const response = await axiosInstance.get(`/projects/${project.id}/users`);
            setProjectUsers(response.data || []);
        } catch (err) {
            console.error('Ошибка при добавлении участников:', err);
        }
    };

    const removeUserFromProject = async (userId) => {
        try {
            await axiosInstance.delete(`/projects/${project.id}/users/${userId}`);
            const response = await axiosInstance.get(`/projects/${project.id}/users`);
            setProjectUsers(Array.isArray(response.data) ? response.data : []);
        } catch (err) {
            console.error('Ошибка при удалении участника:', err);
            toast.error('Ошибка при удалении участника');
        }
    };

    const translateRole = (role) => {
        switch (role) {
            case 'participant':
                return 'Участник';
            case 'manager':
                return 'Руководитель проекта';
            default:
                return 'Неизвестная роль';
        }
    };

    return (
        <div className="project" ref={modalRef}>
            <button className="project__close" onClick={() => setIsProjectOpen(false)}>
                &times;
            </button>
            <h2 className="project__title">{project.name}</h2>
            <p className="project__description">
                <span>Описание:</span> {project.description}
            </p>
            <p className="project__description">
                <span>Создан:</span> {new Date(project.created_at).toLocaleString()}
            </p>
            <p className="project__description">
                <span>Обновлён:</span> {new Date(project.updated_at).toLocaleString()}
            </p>

            <h3 className="project__heading">Участники проекта</h3>
            <ul className="project__list">
                {projectUsers.length > 0 ? (
                    projectUsers.map((user) => (
                        <li className="project__list-item" key={user.id}>
                            {user.name} — {translateRole(user.role)}
                            {currentUserRole === 'manager' && (
                                <button onClick={() => removeUserFromProject(user.id)}>Удалить</button>
                            )}
                        </li>
                    ))
                ) : (
                    <p>Участники отсутствуют</p>
                )}
            </ul>

            {currentUserRole === 'manager' && (
                <div className="project-management">
                    <h3 className="project__heading">Добавить участников</h3>
                    <AutocompleteInput
                        options={allUsers.filter(
                            (user) => !projectUsers.some((projectUser) => projectUser.id === user.id)
                        )}
                        selectedItems={newUserRole.observers || []}
                        onChange={(ids) => setNewUserRole({ ...newUserRole, observers: ids })}
                        label="Выберите участников"
                        displayField="name"
                        isMulti={true}
                        isDisabled={false}
                    />

                    <select
                        className="project__select"
                        value={newUserRole.role}
                        onChange={(e) => setNewUserRole({ ...newUserRole, role: e.target.value })}
                    >
                        <option value="participant">Участник</option>
                        <option value="manager">Руководитель проекта</option>
                    </select>

                    <button className="project__button" onClick={addUsersToProject}>
                        Добавить
                    </button>
                </div>
            )}
        </div>
    );
}

export default ProjectModal;

