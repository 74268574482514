import React, { useState } from 'react';
import axios from './modules/axiosInstance';
import './styles/NotificationManagement.css';
import RichTextEditor from './connectables/RichTextEditor';

function NotificationManagement({ user }) {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [message, setMessage] = useState('');

  const handleGroupChange = (e) => {
    const { options } = e.target;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedGroups(selectedValues);
  };

  const sendNotification = async () => {
    try {
      await axios.post('/send-notification', {
        groups: selectedGroups,
        message,
        senderId: user.id
      });
      setMessage('');
    } catch (error) {
      console.error("Ошибка при отправке уведомления:", error);
    }
  };

  return (
    <div className="notification-management-container">
      <h2>Управление уведомлениями</h2>

      <div className="notification-management">
        <label>Выберите группы:</label>
        <select multiple={true} value={selectedGroups} onChange={handleGroupChange}>
          <option value="Admin">Admin</option>
          <option value="Proofreader">Proofreader</option>
          <option value="Editor">Editor</option>
          <option value="Videoadmin">Videoadmin</option>
          <option value="Montazh">Montazh</option>
          <option value="Test">Test</option>
        </select>
      </div>

      <div className="notification-management">
        <label>Сообщение:</label>
        <RichTextEditor
          placeholder={'Текст уведомления'}
          newContent={message}
          setNewContent={setMessage}
        /><br />
      </div>

      <button className="button" onClick={sendNotification}>Отправить уведомление</button>
    </div>
  );
}

export default NotificationManagement;
