import React, { useState, useEffect } from 'react';
import axios from './modules/axiosInstance';
import UserProfile from './modules/UserProfile';
import './styles/AccountManagement.css';

function AccountManagement({ user }) {
  // Существующие состояния
  const [newUsername, setNewUsername] = useState('');
  const [newName, setNewName] = useState('');
  const [newGroup, setNewGroup] = useState('');
  const [message, setMessage] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showMoreOptions, setShowMoreOptions] = useState({});
  const [groupsList, setGroupsList] = useState([]);

  // Состояния для фильтрации, сортировки и выбора группы
  const [filterText, setFilterText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('all'); // 'all' означает, что фильтрация по группе не применяется
  const [selectedRole, setSelectedRole] = useState('all'); // Фильтрация по ролям (user, admin, block, all)

  // Загружаем список пользователей при загрузке компонента
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users');
        setUsersList(response.data.users);
        setGroupsList(response.data.groups);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  if (user.role !== 'manager') {
    return <p>У вас нет прав доступа к этой странице.</p>;
  }

  const handleOpenProfile = (userItem) => {
    setSelectedUser(userItem);
  };

  const handleCloseProfile = () => {
    setSelectedUser(null);
  };

  // Функция для фильтрации и сортировки списка пользователей
  const getFilteredAndSortedUsers = () => {
    let filtered = usersList;

    // Фильтрация по текстовому запросу (логин, имя, группа)
    if (filterText) {
      filtered = filtered.filter(u =>
        (u.username && u.username.toLowerCase().includes(filterText.toLowerCase())) ||
        (u.name && u.name.toLowerCase().includes(filterText.toLowerCase())) ||
        (u.group && u.group.toLowerCase().includes(filterText.toLowerCase()))
      );
    }

    // Фильтрация по выбранной группе (если не выбрано "all")
    if (selectedGroup !== 'all') {
      filtered = filtered.filter(u => u.group === selectedGroup);
    }

    if (selectedRole !== 'all') {
      filtered = filtered.filter(u => u.role === selectedRole);
    }

    return filtered;
  };

  const filteredUsers = getFilteredAndSortedUsers();

  // Вычисляем уникальные группы для отображения кнопок фильтрации
  const uniqueGroups = Array.from(new Set(usersList.map(u => u.group).filter(Boolean)));

  // Функция для добавления нового пользователя
  const handleAddUser = async () => {
    try {
      const response = await axios.post('/add-user', {
        username: newUsername,
        name: newName,
        group: newGroup,
        sheet: newGroup,
      });

      if (response.data.message === 'Пользователь успешно добавлен') {
        setMessage('Новый пользователь успешно добавлен');
        setNewUsername('');
        setNewName('');
        setNewGroup('');
        setUsersList([...usersList, {
          id: response.data.id,
          username: response.data.username,
          name: response.data.name,
          group: response.data.group,
          sheet: response.data.sheet,
          role: response.data.role,
          statuses: response.data.statuses,
        }]);
      }
    } catch (error) {
      console.error('Error adding user:', error);
      setMessage('Ошибка при добавлении пользователя');
    }
  };

  const handleDeleteUser = async (usernameToDelete) => {
    const confirmed = window.confirm(`Вы уверены, что хотите удалить пользователя ${usernameToDelete}?`);
    if (!confirmed) return;

    try {
      const response = await axios.post('/delete-user', { id: usernameToDelete });
      if (response.data.message === 'Пользователь успешно удален') {
        setMessage('Пользователь успешно удален');
        setUsersList(usersList.filter((u) => u.id !== usernameToDelete));
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setMessage('Ошибка при удалении пользователя');
    }
  };

  const handleToggleMoreOptions = (userId) => {
    setShowMoreOptions((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  const handleBlockUser = async (id) => {
    try {
      const response = await axios.put(`/users/${id}/role`, { role: 'block' });
      if (response.status === 200) {
        setUsersList((prev) =>
          prev.map((u) => (u.id === id ? { ...u, role: 'block' } : u))
        );
        setMessage('Пользователь заблокирован');
      }
    } catch (error) {
      console.error('Error blocking user:', error);
      setMessage('Ошибка при блокировке пользователя');
    }
  };

  const handleUnblockUser = async (id) => {
    try {
      const response = await axios.put(`/users/${id}/role`, { role: 'user' });
      if (response.status === 200) {
        setUsersList((prev) =>
          prev.map((u) => (u.id === id ? { ...u, role: 'user' } : u))
        );
        setMessage('Пользователь разблокирован');
      }
    } catch (error) {
      console.error('Error unlocking user:', error);
      setMessage('Ошибка при разблокировке пользователя');
    }
  };

  return (
    <div className="account-management">
      <h2>Управление аккаунтами</h2>

      {/* Форма добавления нового пользователя */}
      <div className='form-add-account'>
        <div className='account-management-div'>
          <label>Логин:</label>
          <input
            type="text"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            placeholder="Введите логин"
          />
        </div>
        <div className='account-management-div'>
          <label>Имя:</label>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Введите имя"
          />
        </div>
        <div className="account-management-div">
          <label>Группа:</label>
          <select value={newGroup} onChange={(e) => setNewGroup(e.target.value)}>
            <option value="" disabled>Выберите группу</option>
            {groupsList.map((group) => (
              <option key={group} value={group}>
                {group}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="account-management-div">
          <label>Лист в Google Sheets:</label>
          <select value={newSheet} onChange={(e) => setNewSheet(e.target.value)}>
            <option value="" disabled>Выберите лист в Google Sheets</option>
            {sheetList.map((sheet) => (
              <option key={sheet} value={sheet}>
                {sheet}
              </option>
            ))}
          </select>
        </div> */}
        <button className="account-management-button" onClick={handleAddUser}>
          Добавить пользователя
        </button>

        {message && <p className='account-management-message'>{message}</p>}
      </div>

      {/* Поиск и кнопки фильтрации */}
      <div className='search-n-buttonfilters'>
        <div className="filter-sort-controls" style={{ marginTop: '20px', marginBottom: '20px' }}>
          <input
            type="text"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            placeholder="Поиск по логину, имени или группе"
          />
        </div>

        <div className="group-buttons" style={{ marginBottom: '20px' }}>
          <button
            className={`account-management-button group ${selectedGroup === 'all' ? 'active' : ''}`}
            onClick={() => setSelectedGroup('all')}
          >
            Все
          </button>
          {uniqueGroups.map((group, idx) => (
            <button
              key={idx}
              className={`account-management-button group ${selectedGroup === group ? 'active' : ''}`}
              onClick={() => setSelectedGroup(group)}
            >
              {group}
            </button>
          ))}
        </div>

        {/* Кнопки фильтрации по ролям */}
        <div className="role-buttons" style={{ marginBottom: '20px' }}>
          <button
            className={`account-management-button role ${selectedRole === 'all' ? 'active' : ''}`}
            onClick={() => setSelectedRole('all')}
          >
            Все роли
          </button>
          <button
            className={`account-management-button role ${selectedRole === 'user' ? 'active' : ''}`}
            onClick={() => setSelectedRole('user')}
          >
            Пользователи
          </button>
          <button
            className={`account-management-button role ${selectedRole === 'manager' ? 'active' : ''}`}
            onClick={() => setSelectedRole('manager')}
          >
            Менеджеры
          </button>
          <button
            className={`account-management-button role ${selectedRole === 'block' ? 'active' : ''}`}
            onClick={() => setSelectedRole('block')}
          >
            Заблокированные
          </button>
        </div>
      </div>

      <h3>Список пользователей</h3>
      <ul>
        {filteredUsers.map((userItem, index) => (
          <li key={index} className='user-list'>
            <span className='account-management-user'>
              {userItem.username} ({userItem.name}) - {userItem.group}
            </span>
            <div className='user-management_action-button'>
              <div className='additional-options'>
                {user.id !== userItem.id && (
                  <button className='account-management-button' onClick={() => handleOpenProfile(userItem)}>
                    Открыть профиль
                  </button>
                )}
                <button onClick={() => handleToggleMoreOptions(userItem.id)} className="account-management-button">
                  Еще
                </button>
              </div>
              {showMoreOptions[userItem.id] && (
                <div className='additional-options' style={{ gap: '15px' }}>
                  <button className='account-management-button block' onClick={() => handleDeleteUser(userItem.id)}>
                    Удалить
                  </button>
                  {userItem.role !== 'block' && (
                    <button className='account-management-button block' onClick={() => handleBlockUser(userItem.id)}>
                      Заблокировать
                    </button>
                  )}
                  {userItem.role === 'block' && (
                    <button className='account-management-button block' onClick={() => handleUnblockUser(userItem.id)}>
                      Разблокировать
                    </button>
                  )}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>

      {selectedUser && (
        <>
          <div className="user-profile__overlay" onClick={handleCloseProfile}></div>
          <UserProfile
            user={selectedUser}
            isOwnProfile={user.id === selectedUser.id}
            currentUser={user}
            updateUser={(updatedUser) => {
              setUsersList((prev) =>
                prev.map((u) => (u.id === updatedUser.id ? updatedUser : u))
              );
              setSelectedUser(updatedUser);
            }}
            toggleProfile={handleCloseProfile}
          />
        </>
      )}
    </div>
  );
}

export default AccountManagement;
